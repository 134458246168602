import { store, USER } from 'util/store';
import { getCookie } from 'util/index';
import { isPrerender } from 'library/util/index';
import { load } from './urlService';

export function isUsa() {
	let behaviorCookie = getCookie('notice_behavior');
	return behaviorCookie.includes('us');
}

export function isEurope() {
	let behaviorCookie = getCookie('notice_behavior');
	return behaviorCookie.includes('eu');
}

/**
 * returns a boolean that is true if no privacy legislation applies for the region this user is from
 */
function noPrivacyLegislation() {
	let behaviorCookie = getCookie('notice_behavior');
	return behaviorCookie === 'none' || isPrerender;
}

export function advertisementCookiesEnabled(user) {
	let preferenceCookie = getCookie('notice_preferences');

	if (noPrivacyLegislation()) {
		return true;
	}

	// CCPA
	if (isUsa()) {
		if (user.age < 16) {
			return preferenceCookie && preferenceCookie !== '0:' && preferenceCookie !== '1:';
		}
		return !preferenceCookie || (preferenceCookie !== '0:' && preferenceCookie !== '1:');
	}

	// GDPR
	if (isEurope()) {
		return preferenceCookie && preferenceCookie !== '0:' && preferenceCookie !== '1:';
	}
}

export function functionalCookiesEnabled(user) {
	let preferenceCookie = getCookie('notice_preferences');

	if (noPrivacyLegislation()) {
		return true;
	}

	// CCPA
	if (isUsa()) {
		if (user.age < 16) {
			return preferenceCookie && preferenceCookie !== '0:';
		}
		return !preferenceCookie || preferenceCookie !== '0:';
	}

	// GDPR
	if (isEurope()) {
		return preferenceCookie && preferenceCookie !== '0:';
	}
}

function sendCookieConsentTelemetry(user) {
	// TODO: not tracked in GA4
}

function handleMessage(e) {
	try {
		if (typeof e.data === 'string') {
			let json = JSON.parse(e.data);
			if (json.message && json.message === 'submit_preferences') {
				setTimeout(() => {
					let { [USER]: user } = store.getState();
					sendCookieConsentTelemetry(user);
					// page reload needed to make the new privacy setting take affect
					load('refresh', 'cookie-consent-submit');
				},200);
			}
		}
	}
	catch (e) {
		// message is not about GDPR
	}
}

window.addEventListener('message',  handleMessage,  false);
