/* globals Sentry, CLIENT_SENTRY_DSN, CLIENT_SENTRY_DSN, POGO_ENVIRONMENT, CLIENT_VERSION, CLIENT_SENTRY_ENABLED */
import { stringify } from 'util/index';
import { USER, store } from 'util/store';
import { dispatchEvent, setLogger } from '@pogo-internal/events/eventManager';
import { ERROR } from '@pogo-internal/events/events';
import { MESSAGE, SOURCE, STACK } from '@pogo-internal/events/attributes';

try {
	if (CLIENT_SENTRY_ENABLED) {
		Sentry.init({
			dsn: CLIENT_SENTRY_DSN,
			release: CLIENT_VERSION,
			debug: true,
			sampleRate: 1,
			integrations(integrations) {
				// try catch integration is very buggy and causes a lot of noise alerts
				return integrations.filter((integration) => integration.name !== 'TryCatch');
			},
			allowUrls: [
				/https:\/\/www\.pogo\.com/,
				/https:\/\/new-pogo\.ea\.com/,
				/https:\/\/www\.staging.pogo\.com/,
				/https:\/\/www\.integration\.pogo\.com/,
				/https:\/\/integration-pogo\.ea\.com/,
				/https:\/\/www\.pogodev\.pogo\.com/,
				/https:\/\/www\.pogodev-pogo\.ea\.com/,
				/https:\/\/consent\.trustarc\.com/
			],
			ignoreErrors: [
				'Script error.',
				'ResizeObserver loop limit exceeded',
				'ResizeObserver loop completed with undelivered notifications',
				'198230182308109283091823098102938908128390',
				'browserNotificationOpenUi',
				'SecurityError: The operation is insecure.',
				'can\'t redefine non-configurable property "userAgent"',
				'Can\'t execute code from a freed script',
				'top.GLOBALS',
				'originalCreateNotification',
				'canvas.contentDocument',
				'MyApp_RemoveAllHighlights',
				'http://tt.epicplay.com',
				'Can\'t find variable: ZiteReader',
				'jigsaw is not defined',
				'ComboSearch is not defined',
				'http://loading.retry.widdit.com/',
				'atomicFindClose',
				'fb_xd_fragment',
				'bmi_SafeAddOnload',
				'EBCallBackMessageReceived',
				'conduitPage'
			]
		});
		Sentry.setTag('environment', POGO_ENVIRONMENT);
	}
}
catch (e) {
	logError(e, 'logger - Sentry init');
}

/**
 * log all unhandled promise rejections
 */
window.addEventListener('unhandledrejection', event => {
	log(`unhandledrejection ${(event.reason && event.reason.message) || event.reason ||
	'unhandledrejection without a reason'}`, event.reason && event.reason.stack);
});

/**
 * log all errors on the page
 */
window.addEventListener('error', event => {
	logError(event.error || event);
});

export function logDebug(msg) {
	// eslint-disable-next-line no-console
	store.getState()?.[USER]?.segments?.includes('debugJS') && console.log(msg);
}

export function logError(e = {}, location, tags) {
	e = sanitize(e);
	log(e.message, e.stack ? e.stack : '', e, false, location);
	logErrorToSentry(e, location, tags);
}

setLogger(logError);

/**
 * log error with stack trace to GA
 */
function log(message, stack = '', error,  external, location) {
	if (typeof stack === 'object') {
		stack = stringify(stack);
	}
	if  (!message) {
		message = error ? stringify(error) : 'error is undefined';
	}
	if (message.toLowerCase().includes('script error') || (stack && !stack.toLowerCase().includes('pogo.com'))) {
		external = true;
	}
	if (location) {
		message = location + ' - ' + message;
	}
	console.error(message + '\n' + stack);

	dispatchEvent(ERROR, { [SOURCE]: `${external ? 'external' : 'client'}`, [MESSAGE]: message, [STACK]: stack });
}

function logErrorToSentry(error, location, tags = {}) {
	if (Object.prototype.hasOwnProperty.call(window, 'Sentry')) {
		let { [USER]: user } = store.getState();
		Sentry.captureException(error, scope => {
			if (user?.id) {
				scope.setUser({
					id: user.id,
					username: user.authenticated ? user.screenName : 'guest' + user.id
				});
				scope.setTag('authLevel', user.authLevel);
				scope.setTag('message', error?.message?.slice(0, 200) || 'no error message');
			}
			if (user?.authenticated) {
				scope.setTag('nucleusId', user.nucleusId);
				scope.setTag('personaId', user.personaId);
			}
			for (const [key, value] of Object.entries(tags)) {
				scope.setTag(key, value);
			}
			scope.setTag('location', location);
		});
	}
}

function sanitize(error) {
	if (!isError(error)) {
		error = new Error(error.message || 'No error message');
	}
	return error;
}

function isError(error) {
	return error && (error instanceof Error || (error.stack && error.message));
}

let hidden, visibilityChange;

// detect computer woke up from sleep
let lastTime = (new Date()).getTime();
let checkInterval = 10000;

setInterval(() => {
	let currentTime = (new Date()).getTime();

	if (currentTime > (lastTime + checkInterval * 2)) {  // ignore small delays
		console.log('JS execution was paused'); // eslint-disable-line no-console
	}

	lastTime = currentTime;
}, checkInterval);

// detect tab going to background
if (typeof document.hidden !== 'undefined') { // Opera 12.10 and Firefox 18 and later support
	hidden = 'hidden';
	visibilityChange = 'visibilitychange';
}
else if (typeof document.msHidden !== 'undefined') {
	hidden = 'msHidden';
	visibilityChange = 'msvisibilitychange';
}
else if (typeof document.webkitHidden !== 'undefined') {
	hidden = 'webkitHidden';
	visibilityChange = 'webkitvisibilitychange';
}

document.addEventListener(visibilityChange, handleVisibilityChange, false);

function handleVisibilityChange() {
	if (document[hidden]) {
		console.log('browser tab is now hidden'); // eslint-disable-line no-console
	}
	else {
		console.log('browser tab is now visible'); // eslint-disable-line no-console
	}
}