import { h } from 'preact';
import { createPortal } from 'preact/compat';
import PropTypes from 'prop-types';

const Portal = ({ children, into, disabled }) => { // *disabled optional
	if (!disabled) {
		let container = document.querySelector(into);
		if (container) {
			return createPortal(children, container);
		}
	}
	return null;
};

export default Portal;

Portal.propTypes = {
	children: PropTypes.array,
	into: PropTypes.string,
	disabled: PropTypes.bool
};

export const PAGE_CONTENT = 'pageContent';
export const CHALLENGEACCESS_FTUE_2_DESKTOP_ID = 'challengeAccessFTUE2_desktop';
export const CHALLENGEACCESS_FTUE_2_MOBILE_ID = 'challengeAccessFTUE2_mobile';
export const CHALLENGEACCESS_FTUE_3_ID = 'challengeAccessFTUE3';
export const CHALLENGEACCESS_FTUE_1_ID = 'challengeAccessFTUE1';
export const CLUBBENEFITS_FTUE_2_ID = 'clubBenefitsFTUE2';
export const CLUBBENEFITS_FTUE_4_ID = 'clubBenefitsFTUE4';
export const GAMECLUBBENEFITS_FTUE_1_ID = 'gameClubBenefitsFTUE1';
export const GAMECLUBBENEFITS_FTUE_2_ID = 'gameClubBenefitsFTUE2';
export const GAMECLUBBENEFITS_FTUE_3_ID = 'gameClubBenefitsFTUE3';