export default function pure(target) {
	if (target.prototype && target.prototype.render) {
		target.prototype.shouldComponentUpdate = shouldComponentUpdate;
	}
}

export function shouldComponentUpdate(props, state) {
	return shallowDiffers(props, this.props) || shallowDiffers(state, this.state);
}

function shallowDiffers(a, b) {
	for (let key in a) if (a[key] !== b[key]) {
		return true;
	}
	for (let key in b) if (!(key in a)) {
		return true;
	}
	return false;
}