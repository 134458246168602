import styles from './index.css';
import PropTypes from 'prop-types';
import { h, Component } from 'preact';
import classnames from 'classnames';
import { connect } from 'unistore/preact';
import { boundMethod } from 'autobind-decorator';
import silentAuth from 'hoc/silentAuth';
import { load, getURL, getInternalUrlFromParam, getUrlOrigin, loadUrl, sendScrollEvent } from 'services/urlService';
import Link from 'library/components/util/link';
import ClubLogo from '!!preact-svg-loader!library/assets/clubLogo.svg';
import LinkIcon from '!!preact-svg-loader!library/assets/icons/flat/externalLink.svg';
import Logo from '!!preact-svg-loader!library/assets/pogoLogo.svg';
import PogoEANetworkLogo from './pogoEANetworkLogo.svg';
import DropdownIcon from '!!preact-svg-loader!library/assets/icons/flat/dropdown.svg';
import Overlay from 'library/components/common/overlay';
import Button from 'library/components/ui/button';
import FavoriteButton from 'async?name=favoriteButton!components/ui/favoriteButton';
import Image from 'library/components/util/image';
import { dispatchEvent  } from '@pogo-internal/events/eventManager';
import { CLICK_LOCATION, DESTINATION_URL, SEARCH_TERM } from '@pogo-internal/events/attributes';
import { CLICK, SEARCH } from '@pogo-internal/events/events';
import { getCategories, getGames, getUserInfo } from 'services/restApi';
import { logError } from 'services/logger';
import { EMPTY_OBJECT, equalOrAboveBreakpoint, merge, stopAllPropagation,
	onWindowResize, removeOnWindowResize, accessibleClick } from 'library/util';
import RightSection from 'async?name=rightSection!components/common/header/rightSection';
import LeftSection from 'components/common/header/leftSection';
import { CATEGORIES_BY_ID, FAVORITE_GAME_CODES, GAMES_BY_ID, HEADER, USER, PREV_URL, CATEGORY_IDS, RECOMMENDED_GAME_CODES, URL } from 'util/store';
import ClubLockIcon from 'library/assets/icons/flat/clubpogolock.svg';
import Accordian from 'components/ui/accordian';
import OnboardingTooltipWithDelay from 'library/components/common/onboarding/tooltipWithDelay';
import { getLocalStorage, setLocalStorage } from 'util/index';
export const headerType = {
	ID20: 'id20',
	SIMPLE: 'simple',
	SUBSCRIBE: 'subscribe',
	TEXT: 'text'
};

export let actions = store => ({
	async initializeHeader() {
		try {
			const getCategoriesPromise = getCategories('header');
			const getGamesPromise = getGames('header');
			const categories = await getCategoriesPromise;
			const games = await getGamesPromise;
			let { [CATEGORIES_BY_ID]: categoriesById, [GAMES_BY_ID]: gamesById, [HEADER]: header } = store.getState();
			gamesById = merge(gamesById, games, 'gameCode');
			categoriesById = merge(categoriesById, categories, 'id');
			store.setState({
				[CATEGORIES_BY_ID]: categoriesById,
				[GAMES_BY_ID]: gamesById,
				[HEADER]: {
					...header,
					[CATEGORY_IDS]: categories.map(category => category.id),
					[RECOMMENDED_GAME_CODES]: games.map(game => game.gameCode)
				}
			});
		}
		catch (e) {
			logError(e, 'Header - initializeHeader');
		}
	},
	async initializeUserInfo() {
		try {
			const getUserInfoPromise = getUserInfo();
			let userInfo = await getUserInfoPromise;
			let { [HEADER]: header } = store.getState();
			store.setState({
				[FAVORITE_GAME_CODES]: userInfo.favorites || [],
				[HEADER]: {
					...header,
					...userInfo
				}
			});
		}
		catch (e) {
			logError(e, 'Header - initializeUserInfo');
		}
	}
});

@silentAuth
export class Header extends Component {

	constructor(props) {
		super(props);
		this.state = {
			search: '',
			headerFavoriteGameCodes: [],
			recommendedGames: [],
			isXLargePlus: true,
			isMediumPlus: false,
			isMediumPlusPlus: false,
			isLargePlus: false
		};
	}

	componentDidMount() {
		this.onResize();
		this.closeAllMenus();
		onWindowResize(this.onResize);
		const guestFirstVisitTime = getLocalStorage('guestFirstVisitTime');
		if (!guestFirstVisitTime) {
			setLocalStorage('guestFirstVisitTime', new Date().getTime());
		}
	}

	componentDidUpdate(prevProps, prevState) {
		const { favoriteGameCodes } = this.props;
		if (favoriteGameCodes !== prevProps.favoriteGameCodes) {
			this.state.showFavoritesMenu && this.updateFavoriteList();
		}
		if (this.state.isXLargePlus !== prevState.isXLargePlus) {
			this.closeAllMenus();
		}
	}

	componentWillUnmount() {
		removeOnWindowResize(this.onResize);
	}

	@boundMethod
	init() {
		if (!Object.values(headerType).includes(this.props.type)) {
			this.props.initializeHeader();
			this.props.initializeUserInfo();
		}

	}

	@boundMethod
	closeAllMenus() {
		this.updateMenuState();
	}

	@boundMethod
	updateMenuState(menus = {}) {
		let {
			selectedCategory,
			showCategoryMenu,
			showHamburgerDrawer,
			showSearchBar,
			showChallengeMenu,
			showChallengeSubMenu,
			showCommunityMenu,
			showPlayerDrawer,
			showFriendsMenu,
			showInboxMenu,
			showFavoritesMenu
		} = menus;
		let showOverlay = Object.keys(menus).filter(menuKey => !!menus[menuKey]).length > 0;
		this.setState({
			selectedCategory,
			showCategoryMenu,
			showHamburgerDrawer,
			showSearchBar,
			showChallengeMenu,
			showChallengeSubMenu,
			showCommunityMenu,
			showPlayerDrawer,
			showOverlay,
			showFriendsMenu,
			showInboxMenu,
			showFavoritesMenu
		});
	}

	@boundMethod
	onNavigateBack() {
		let { prevUrl } = this.props;
		if (prevUrl) {
			dispatchEvent(CLICK, { [CLICK_LOCATION]: 'header-backButton',  [DESTINATION_URL]: prevUrl } );
			sendScrollEvent();
			window.history.back();
		}
		else {
			let redirectUrl = getInternalUrlFromParam('redirect');
			loadUrl(redirectUrl, 'header-backButton');
		}
	}

	updateFavoriteList() {
		this.setState((state, props) => {
			let { favoriteGameCodes } = props;
			let { headerFavoriteGameCodes } = state;
			let updatedFavoriteGameCodes = headerFavoriteGameCodes.concat(favoriteGameCodes.filter(gameCode => headerFavoriteGameCodes.indexOf(gameCode) < 0));
			return {
				headerFavoriteGameCodes: updatedFavoriteGameCodes.slice(0, 10),
				showMoreFavoritesLink: updatedFavoriteGameCodes.length > 10
			};
		});
	}

	@boundMethod
	onResize() {
		this.setState({
			isXLargePlus: equalOrAboveBreakpoint('--enhance-xlarge-plus'),
			isMediumPlus: equalOrAboveBreakpoint('--enhance-medium-plus'),
			isMediumPlusPlus: equalOrAboveBreakpoint('--enhance-medium-plus-plus'),
			isLargePlus: equalOrAboveBreakpoint('--enhance-large-plus'),
			isXLargePlusPlus: equalOrAboveBreakpoint('--enhance-xlarge-plus-plus')
		});
	}

	@boundMethod
	onSearchBarFocus() {
		let { search } = this.state;
		this.updateMenuState({ search, showSearchBar: true });
		dispatchEvent(CLICK, { [CLICK_LOCATION]: 'header-search' });
	}

	@boundMethod
	onSearchBarInput(event) {
		let search = event.target.value;
		this.setState((state, props) => {
			let { gamesById, header: { recommendedGameCodes } } = props;
			let recommendedGames = recommendedGameCodes
				.filter(gameCode =>
					gamesById[gameCode].shortName
						.replace(/[\u2122]/g, '')
						.toLowerCase()
						.includes(search.toLowerCase()))
				.slice(0, 5);
			return {
				search,
				recommendedGames
			};
		});
	}

	@boundMethod
	onSearchGame(event) {
		let { search } = this.state;
		if ((event.type === 'click' || event.type === 'keydown' && event.keyCode === 13) && search) {
			dispatchEvent(SEARCH, { [SEARCH_TERM]: search.trim() });
			load('search', 'header-search', { query: search.trim() });
			this.closeAllMenus();
		}
	}

	@boundMethod
	onToggleCategory(category) {
		return () => {
			this.setState(state => {
				let { selectedCategory, isXLargePlus } = state;
				if (category && category !== selectedCategory) {
					dispatchEvent(CLICK, { [CLICK_LOCATION]: `${isXLargePlus ? 'header-games' : 'header-left-drawer'}-${category}` });
				}
				return {
					selectedCategory: selectedCategory !== category ? category : false
				};
			});
		};
	}

	@boundMethod
	onToggleCategoryMenu() {
		let { showCategoryMenu: previousState = false, isXLargePlus } = this.state;
		if (!previousState) {
			dispatchEvent(CLICK, { [CLICK_LOCATION]: `header-${isXLargePlus ? '' : 'left-drawer-'}games` });
		}
		this.updateMenuState({ showCategoryMenu: !previousState, showHamburgerDrawer: !isXLargePlus });
	}

	@boundMethod
	onToggleFriendsMenu() {
		let { showFriendsMenu: previousState = false } = this.state;
		this.updateMenuState({ showFriendsMenu: !previousState });
		dispatchEvent(CLICK, { [CLICK_LOCATION]: 'Header-Friends-Access' });
	}

	@boundMethod
	onToggleInboxMenu() {
		dispatchEvent(CLICK, { [CLICK_LOCATION]: 'header-inbox' });
		let { showInboxMenu: previousState = false } = this.state;
		this.updateMenuState({ showInboxMenu: !previousState });
	}

	@boundMethod
	onToggleFavoritesMenu() {
		this.setState((state, props) => {
			let { showFavoritesMenu: previousState = false, isLargePlus } = state;
			if (!previousState) {
				dispatchEvent(CLICK, { [CLICK_LOCATION]: `header-${isLargePlus ? '' : 'right-drawer-'}favorites` });
			}
			this.updateMenuState({ showFavoritesMenu: !previousState });
			return ({
				headerFavoriteGameCodes: props.favoriteGameCodes.slice(0, 10),
				showMoreFavoritesLink: props.favoriteGameCodes.length > 10
			});
		});
	}

	@boundMethod
	onToggleHamburgerDrawer() {
		let { showHamburgerDrawer: previousState = false } = this.state;
		if (!previousState) {
			dispatchEvent(CLICK, { [CLICK_LOCATION]: 'header-left-drawer' });
		}
		this.updateMenuState({ showHamburgerDrawer: !previousState });
	}

	@boundMethod
	onToggleSearchBar() {
		let { showSearchBar: previousState = false } = this.state;
		this.updateMenuState({ showSearchBar: !previousState });
	}

	@boundMethod
	onToggleChallengeMenu() {
		let { showChallengeMenu: previousState = false, isXLargePlus } = this.state;
		let menuName = this.props?.user?.guest ? 'clubpogo' : 'challenge';
		if (!previousState) {
			dispatchEvent(CLICK, { [CLICK_LOCATION]: `header-${isXLargePlus ? menuName : 'left-drawer-challenge'}` });
		}
		this.updateMenuState({ showChallengeMenu: !previousState, showHamburgerDrawer: !isXLargePlus });
	}

	@boundMethod
	onToggleChallengeSubMenu() {
		let { showChallengeSubMenu: previousState = false } = this.state;
		// there is no Challenge Sub Menu in mobile view
		if (!previousState) {
			dispatchEvent(CLICK, { [CLICK_LOCATION]: `header-challenges` });
		}
		this.updateMenuState({ showChallengeMenu: true, showChallengeSubMenu: !previousState });
	}

	@boundMethod
	onToggleCommunityMenu() {
		let { showCommunityMenu: previousState = false, isXLargePlus } = this.state;
		if (!previousState) {
			dispatchEvent(CLICK, { [CLICK_LOCATION]: `header-${isXLargePlus ? '' : 'left-drawer-'}community` });
		}
		this.updateMenuState({ showCommunityMenu: !previousState, showHamburgerDrawer: !isXLargePlus });
	}

	@boundMethod
	onTogglePlayerDrawer() {
		let { showPlayerDrawer: previousState = false } = this.state;
		if (!previousState) {
			dispatchEvent(CLICK, { [CLICK_LOCATION]: 'header-right-drawer' });
		}
		this.updateMenuState({ showPlayerDrawer: !previousState });
	}

	render({
		user,
		categoriesById,
		gamesById,
		header,
		headerCopy,
		type,
		class: additionalClass
	}, {
		headerFavoriteGameCodes,
		isMediumPlus,
		isMediumPlusPlus,
		isLargePlus,
		isXLargePlus,
		isXLargePlusPlus,
		recommendedGames,
		search,
		selectedCategory,
		showCategoryMenu,
		showSearchBar,
		showChallengeMenu,
		showChallengeSubMenu,
		showCommunityMenu,
		showHamburgerDrawer,
		showPlayerDrawer,
		showOverlay,
		showFriendsMenu,
		showInboxMenu,
		showFavoritesMenu,
		showMoreFavoritesLink
	}) {
		const authenticated = user?.authenticated;
		const isGuest = user && !user.authenticated;
		const isClub = user?.club;

		switch (type) {
			case headerType.ID20:
				return (
					<header class={styles.id2Header}>
						<Image src={PogoEANetworkLogo} alt={'Pogo EA Network Logo'} class={styles.id2Logo} />
					</header>
				);
			case headerType.SIMPLE:
				return (
					<header class={styles.header}>
						<div class={styles.centeredLogo}>
							<Logo
								class={styles.logo}
							/>
						</div>
					</header>
				);
			case headerType.TEXT:
				return (
					<header class={classnames(styles.header, styles.secondary)}>
						<DropdownIcon class={styles.backButton} onClick={this.onNavigateBack} />
						<div class={styles.pageName}>{headerCopy}</div>
					</header>
				);
			case headerType.SUBSCRIBE: {
				// we need to get correct domain from redirectUrl for home page
				let homeUrl;
				if (user) {
					let redirectUrl = getInternalUrlFromParam('redirect');
					let origin = getUrlOrigin(redirectUrl);
					homeUrl = origin + getURL('home');
				}
				let SubsLogoWrapper = homeUrl ? Link : 'div';
				return (
					<header class={classnames(styles.header, styles.secondary)}>
						<SubsLogoWrapper class={styles.subsHeaderLogo} pageLocation={'subs-header'} href={homeUrl}>
							{
								isClub ?
									<ClubLogo class={styles.logo} /> :
									<Logo class={styles.logo} />
							}
						</SubsLogoWrapper>
						<div class={styles.pageName}>{headerCopy}</div>
					</header>
				);
			}
		}

		return (
			<header class={classnames(styles.header, additionalClass)}>
				{
					showOverlay && <Overlay type={'venus-header'} class={classnames(styles.overlay, (showPlayerDrawer || showHamburgerDrawer) && styles.drawerOverlay)} onClick={this.closeAllMenus} />
				}
				<div class={styles.leftSection}>
					<LeftSection
						user={user}
						header={header}
						search={search}
						recommendedGames={recommendedGames}
						categoriesById={categoriesById}
						isClub={isClub}
						showHamburgerDrawer={showHamburgerDrawer}
						isXLargePlus={isXLargePlus}
						showCategoryMenu={showCategoryMenu}
						showChallengeMenu={showChallengeMenu}
						showChallengeSubMenu={showChallengeSubMenu}
						showCommunityMenu={showCommunityMenu}
						showSearchBar={showSearchBar}
						onSearchGame={this.onSearchGame}
						onSearchBarFocus={this.onSearchBarFocus}
						onSearchBarInput={this.onSearchBarInput}
						onToggleHamburgerDrawer={this.onToggleHamburgerDrawer}
						onToggleSearchBar={this.onToggleSearchBar}
						onToggleCategoryMenu={this.onToggleCategoryMenu}
						onToggleChallengeMenu={this.onToggleChallengeMenu}
						onToggleChallengeSubMenu={this.onToggleChallengeSubMenu}
						onToggleCommunityMenu={this.onToggleCommunityMenu}
						onToggleCategory={this.onToggleCategory}
						selectedCategory={selectedCategory}
						gamesById={gamesById}
					/>
				</div>
				<div class={styles.rightSection}>
					{
						authenticated &&
						<RightSection
							isMediumPlus={isMediumPlus}
							isMediumPlusPlus={isMediumPlusPlus}
							isLargePlus={isLargePlus}
							isXLargePlusPlus={isXLargePlusPlus}
							headerFavoriteGameCodes={headerFavoriteGameCodes}
							showFriendsMenu={showFriendsMenu}
							showInboxMenu={showInboxMenu}
							showFavoritesMenu={showFavoritesMenu}
							showMoreFavoritesLink={showMoreFavoritesLink}
							showPlayerDrawer={showPlayerDrawer}
							onToggleFriendsMenu={this.onToggleFriendsMenu}
							onToggleInboxMenu={this.onToggleInboxMenu}
							onTogglePlayerDrawer={this.onTogglePlayerDrawer}
							onToggleFavoritesMenu={this.onToggleFavoritesMenu}
						/>
					}
					{
						isGuest &&
						<div class={styles.unauthenticatedSection}>
							<div class={styles.whyRegister}>
								<Link class={classnames(styles.headerLink, styles.whyRegister)} hrefKey={'clubPogo'} pageLocation={'header'}>
									<Image alt={'Club Lock Icon'} src={ClubLockIcon} class={styles.headerIcon} /> Why Register?
								</Link>
								<OnboardingTooltipWithDelay
									visibleEqualOrAboveBreakpoint={'isMediumPlusPlus'}
									class={styles.tooltip}
									arrowPosition={'topRight'}
									tooltipId={'whyRegister'}
									timestamp={getLocalStorage('guestFirstVisitTime')}
									location={'why register'}
								/>
							</div>
							<div class={classnames(styles.headerLink, styles.clubPogo)}>
								<Accordian
									header={
										<NavItem
											selected={showChallengeMenu}
											isLink={false}
											showDropDownIcon
											primary
										>
											Club Pogo{' '}
										</NavItem>
									}
									onClick={this.onToggleChallengeMenu}
									open={showChallengeMenu}
								>
									<div class={styles.clubPogoDropdown}>
										<NavItem pageLocation={`header-clubpogo`} hrefKey={'challengeCentral'} dropdown underline>
											Challenge Central
										</NavItem>
										<NavItem pageLocation={`header-clubpogo`} hrefKey={'challengeFilter'} dropdown>
											All Challenges
										</NavItem>
										<NavItem pageLocation={`header-clubpogo`} hrefKey={'myCollection'} dropdown>
											My Collection
										</NavItem>
										<NavItem pageLocation={`header-clubpogo`} hrefKey={'challengeSeason'} useDiv dropdown>
											Pogi Constellations
										</NavItem>
										<NavItem safe pageLocation={`header-clubpogo`} hrefKey={'blog'} class={styles.categoryItem} external dropdown>
											pogo blog
										</NavItem>
									</div>
								</Accordian>
							</div>
							<Link class={styles.signIn} hrefKey={'signIn'} pageLocation={'header'} intcmp={'header-signin'}>
								<Button class={styles.registerCTA} type={'secondary'}>Sign In</Button>
							</Link>
							<Link hrefKey={'register'} pageLocation={'header'} intcmp={'header-register'}>
								<Button class={styles.registerCTA} type={'primary'}>
									{
										equalOrAboveBreakpoint('--enhance-small-plus-plus') ?
											'Register free' : 'Register'
									}
								</Button>
							</Link>
						</div>
					}
				</div>
				<div class={styles.headerShadow} />
			</header>
		);
	}
}

Header.propTypes = {
	user: PropTypes.object,
	categoriesById: PropTypes.object,
	gamesById: PropTypes.object,
	header: PropTypes.object,
	type: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
	class: PropTypes.string,
	favoriteGameCodes: PropTypes.array,
	initializeHeader: PropTypes.func,
	initializeUserInfo: PropTypes.func,
	prevUrl: PropTypes.string
};

export default connect(`${CATEGORIES_BY_ID}, ${FAVORITE_GAME_CODES}, ${GAMES_BY_ID}, ${HEADER}, ${USER}, ${PREV_URL}`, actions)(Header);

// TODO: abstract the <Menu /> into a generic component

export function MenuButton({
	class: additionalClass,
	children,
	accessibleWrapper, // boolean that indicates if wrapper should be made accessible
	selected,
	onClick
}) {
	return (
		<div class={classnames(styles.menuButtonWrapper, selected && styles.selected, additionalClass)}
			 onKeyPress={accessibleClick(onClick)} onClick={onClick} role={accessibleWrapper ? 'button' : undefined}
			 tabIndex={accessibleWrapper ? '0' : undefined}
		>
			{children}
		</div>
	);
}

MenuButton.propTypes = {
	class: PropTypes.string,
	children: PropTypes.any,
	selected: PropTypes.bool,
	onClick: PropTypes.func,
	accessibleWrapper: PropTypes.bool
};

export function CategoryItem({
	class: additionalClass,
	category = EMPTY_OBJECT,
	isLink,
	moreGames,
	selected,
	onClick,
	showDropDownIcon,
	pageLocation = 'header-category',
	doNotNavigate
}) {
	return (
		<NavItem
			pageLocation={pageLocation}
			class={classnames(styles.categoryItem, moreGames && styles.moreGames, additionalClass)}
			hrefKey={`category/${category.id}`}
			isLink={isLink}
			onClick={onClick}
			selected={selected}
			doNotNavigate={doNotNavigate}
			showDropDownIcon={showDropDownIcon}
		>
			{moreGames ? `Browse All ${category.name} Games` : category.name +
				(equalOrAboveBreakpoint('--enhance-xlarge-plus') ? '' : ' games')}
		</NavItem>
	);
}

CategoryItem.propTypes = {
	class: PropTypes.string,
	category: PropTypes.object,
	isLink: PropTypes.bool,
	moreGames: PropTypes.bool,
	selected: PropTypes.bool,
	showDropDownIcon: PropTypes.bool,
	onClick: PropTypes.func,
	pageLocation: PropTypes.string,
	doNotNavigate: PropTypes.bool
};

export function GameItem({
	game = EMPTY_OBJECT,
	class: additionalClass,
	pageLocation,
	autoPlay,
	showFavoriteIcon
}) {
	return (
		<NavItem
			pageLocation={pageLocation}
			class={classnames(styles.gameItem, additionalClass)}
			hrefKey={autoPlay ? `game/${game.gameCode}` : `pregame/${game.gameCode}`}
		>
			{showFavoriteIcon && <FavoriteButton gameCode={game.gameCode} favorite={game.favorite} class={styles.favoriteButton} location={'header'} />}
			{game?.shortName}
		</NavItem>
	);
}

GameItem.propTypes = {
	game: PropTypes.object,
	class: PropTypes.string,
	pageLocation: PropTypes.string,
	autoPlay: PropTypes.bool,
	showFavoriteIcon: PropTypes.bool
};

function _NavItem({
	children,
	class: additionalClass,
	hrefKey,
	onClick,
	useDiv = false,
	external = false,
	isLink = true,
	safe = false, // indicates external link is trusted
	primary,
	selected,
	url: currentUrl,
	pageLocation = 'header',
	dropdown,
	doNotNavigate,
	underline,
	showDropDownIcon
}) {
	let href = getURL(hrefKey);
	let Component, props;
	if (doNotNavigate && isLink) {
		Component = 'a';
		props = { href };
	}
	else if (isLink) {
		Component = Link;
		props = { hrefKey };
	}
	else {
		Component = 'div';
	}

	if (currentUrl && currentUrl.includes('?')) {
		currentUrl = currentUrl.substring(0, currentUrl.indexOf('?'));
	}

	selected = selected || (currentUrl && currentUrl === href);

	function handleClick(event) {
		if (doNotNavigate) stopAllPropagation(event);
		onClick && onClick();
	}

	return (
		<Component
			pageLocation={pageLocation}
			safe={safe}
			class={classnames(styles.link, external && styles.external, primary && styles.primaryLink, selected && styles.selected, dropdown && styles.dropdown, underline && styles.underline, additionalClass)}
			target={external ? '_blank' : undefined}
			onClick={handleClick}
			useDiv={useDiv}
			{...props} // eslint-disable-line react/jsx-props-no-spreading
		>
			<div class={styles.linkHeader}>
				{children}
				{showDropDownIcon && <DropdownIcon class={styles.dropdownIcon} />}
				{
					external &&
					<span class={styles.externalLinkIcon}>
						{' '}
						<LinkIcon src={LinkIcon} />
					</span>
				}
			</div>
		</Component>
	);
}

export const NavItem = connect(URL)(_NavItem);

_NavItem.propTypes = {
	children: PropTypes.any,
	class: PropTypes.string,
	hrefKey: PropTypes.string,
	onClick: PropTypes.func,
	useDiv: PropTypes.bool,
	external: PropTypes.bool,
	isLink: PropTypes.bool,
	primary: PropTypes.bool,
	selected: PropTypes.bool,
	safe: PropTypes.bool,
	url: PropTypes.string,
	pageLocation: PropTypes.string,
	dropdown: PropTypes.bool,
	doNotNavigate: PropTypes.bool,
	underline: PropTypes.bool,
	showDropDownIcon: PropTypes.bool
};