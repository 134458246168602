import { h, Component } from 'preact';
import { boundMethod } from 'autobind-decorator';
import styles from './index.css';
import classnames from 'classnames';
import LinkIcon from '!!preact-svg-loader!library/assets/icons/flat/externalLink.svg';
import { getURL, addQueryParams, isExternal, loadUrl } from 'services/urlService';
import PropTypes from 'prop-types';
import { stopAllPropagation, accessibleClick } from 'library/util';

export default class Link extends Component {
	constructor(props) {
		super(props);
		let { pageLocation, href, hrefKey, target, useDiv, safe } = this.props;
		href = href || getURL(hrefKey);
		if (!pageLocation || !href) {
			throw new Error(`pageLocation ${pageLocation} and href ${href} are required props`);
		}
		this.useDiv = useDiv || (isExternal(href, target) && !safe);
	}

	/**
	 * if its an external link or opens in a new tab then we use an event to
	 * send heatmap custom dimension, otherwise we just set the custom dimension
	 * and it is sent along with pageview of the page user is navigating to
	 */
	@boundMethod
	onClick(event) {
		stopAllPropagation(event);
		let { pageLocation, target, href, hrefKey, queryParams, intcmp, fragment, onClick, safe } = this.props;
		onClick && onClick();
		href = href || getURL(hrefKey);
		href = addQueryParams(href, queryParams);
		href = fragment ? `${href}#${fragment}` : href;
		loadUrl(href, pageLocation, intcmp, true, target, safe);
	}

	render({
		javascript,
		children,
		target,
		href,
		hrefKey,
		queryParams,
		externalLinkIcon,
		inline,
		underline,
		class: additionalClasses,
		style: additionalStyle
	}) {
		let props = {};
		if (!this.useDiv) {
			props.href = href || getURL(hrefKey);
			props.target = target;
			if (isExternal(props.href, target))
				props.rel = 'nofollow';
		}
		else {
			props.role = 'link';
			props.tabIndex = '0';
			props.onKeyPress = accessibleClick(this.onClick);
		}
		let Component = this.useDiv ? 'div' : 'a';
		return (
			<Component class={classnames(styles.link, inline && styles.inline, underline && styles.underline, additionalClasses)}
				style={additionalStyle}
				onClick={this.onClick}
				target={props.target}
				href={props.href}
				role={props.role}
				tabIndex={props.tabIndex}
				onKeyPress={props.onKeyPress}
				rel={props.rel}
			>
				{children}
				{externalLinkIcon && <LinkIcon class={styles.linkIcon} />}
			</Component>
		);
	}
}

Link.propTypes = {
	useDiv: PropTypes.bool,
	pageLocation: PropTypes.string,
	intcmp: PropTypes.string,
	javascript: PropTypes.bool,
	children: PropTypes.any,
	target: PropTypes.string,
	href: PropTypes.string,
	hrefKey: PropTypes.string,
	queryParams: PropTypes.object,
	externalLinkIcon: PropTypes.bool,
	inline: PropTypes.bool,
	underline: PropTypes.bool,
	class: PropTypes.string,
	safe: PropTypes.bool,
	fragment: PropTypes.string,
	style: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	onClick: PropTypes.func
};
