import { h } from 'preact';
import styles from './index.css';
import classnames from 'classnames';
import SearchIcon from '!preact-svg-loader!!library/assets/icons/flat/search.svg';
import Button from 'library/components/ui/button';
import PropTypes from 'prop-types';

export default function SearchBar ({
	class: additionalClass,
	value,
	open,
	placeholder,
	autocomplete,
	maxlength,
	onInput,
	onFocus,
	onSearch,
	transparentInput,
	renderSearchButton = false,
	disabled = false
}) {
	return (
		<div class={classnames(styles.searchBarContainer, additionalClass)}>
			<div class={classnames(styles.inputHolder, open ? styles.open : '')}>
				<input
					type="input"
					value={value ? value : ''}
					placeholder={placeholder}
					autocomplete={autocomplete}
					onInput={onInput}
					onfocusin={onFocus}
					class={classnames(styles.searchInput, transparentInput && styles.transparent)}
					maxlength={maxlength}
					onKeyDown={onSearch}
				/>
				<div class={styles.searchIcon} onClick={onSearch}>
					<SearchIcon />
				</div>
				{
					renderSearchButton &&
					<Button type={'secondary'} size={'small'} class={styles.searchButton} onClick={onSearch} disabled={disabled}>
						Search
					</Button>
				}
			</div>
		</div>
	);
}

SearchBar.propTypes = {
	class: PropTypes.string,
	value: PropTypes.string,
	open: PropTypes.bool,
	placeholder: PropTypes.string,
	autocomplete: PropTypes.bool,
	maxlength: PropTypes.number,
	onInput: PropTypes.func,
	onFocus: PropTypes.func,
	onSearch: PropTypes.func,
	transparentInput: PropTypes.bool,
	renderSearchButton: PropTypes.bool,
	disabled: PropTypes.bool
};
