import { h } from 'preact';
import classnames from 'classnames';
import { connect } from 'unistore/preact';
import PropTypes from 'prop-types';
import { Col, Grid, Row } from 'library/components/ui/grid';
import { componentToPageMap } from 'util/index';
import { USER } from 'util/store';
import { memo } from 'preact/compat';
import { Ad } from 'components/ads/static/playwireAd';
import styles from './index.css';

// TODO Move to different files
function Top({ user, path, location, class: additionalClass }) {
	return (
		<div>
			<Grid class={classnames(styles.topAd, additionalClass)}>
				<Row>
					<Col sm={1} smp={1} md={1} mdp={1} lg={1} xl={1}
						 xlp={1}
					>
						<Ad class={styles.topAdSize} user={user} location={location} path={path} id={`${location}_${path}`}
							slots={[
								{ viewport: [0, 0], size: [320, 50] },
								{ viewport: [600, 0], size: [468, 60] },
								{ viewport: [800, 0], size: [728, 90] },
								{ viewport: [960, 0], size: [0, 0] }
							]}
						/>
					</Col>
				</Row>
			</Grid>
		</div>
	);
}

Top.propTypes = {
	user: PropTypes.object,
	path: PropTypes.string,
	location: PropTypes.string,
	class: PropTypes.string
};

function GameTop({ user, path, location, class: additionalClass, init }) {

	return (
		<Grid class={classnames(styles.gameTop, additionalClass)}>
			<Row>
				<Col sm={1} smp={1} md={1} mdp={1} lg={1} xl={1}
					xlp={1}
				>
					<Ad class={styles.gameTopAdSize} user={user} location={location}
						path={path} id={`${location}_${path}`} init={init}
						slots={[
							{ viewport: [0, 0], size: [0, 0] },
							{ viewport: [960, 0], size: [728, 90] },
							{ viewport: [1280, 0], size: [970, 90] }
						]}
					/>
				</Col>
			</Row>
		</Grid>
	);
}
GameTop.propTypes = {
	user: PropTypes.object,
	path: PropTypes.string,
	location: PropTypes.string,
	class: PropTypes.string,
	init: PropTypes.bool
};


function Banner({ user, path, location, class: additionalClass }) {

	return (
		<Grid class={classnames(styles.bannerAd, additionalClass)}>
			<Row>
				<Col sm={1} smp={1} md={1} mdp={1} lg={1} xl={1}
					xlp={1}
				>
					<Ad class={styles.bannerAdSize} user={user} location={location} path={path} id={`${location}_${path}`}
						slots={[
							{ viewport: [0, 0], size: [320, 50] },
							{ viewport: [600, 0], size: [468, 60] },
							{ viewport: [800, 0], size: [728, 90] },
							{ viewport: [1280, 0], size: [970, 90] }
						]}
					/>
				</Col>
			</Row>
		</Grid>
	);
}

Banner.propTypes = {
	user: PropTypes.object,
	path: PropTypes.string,
	location: PropTypes.string,
	class: PropTypes.string
};

function Skyscraper({ user, path, location, noLeftPadding, class: additionalClass, init }) {

	return (
		<Grid class={classnames(styles[location], styles.skyscraper,
			noLeftPadding ? styles.noLeftPadding : '', additionalClass)}
		>
			<Row>
				<Col sm={1} smp={1} md={1} mdp={1} lg={1} xl={1}
					 xlp={1}
				>
					<Ad class={styles.skyscraperAdSize} user={user} location={location}
						path={path} id={`${location}_${path}`} init={init}
						slots={[
							{ viewport: [0, 0], size: [0, 0] },
							{ viewport: [960, 0], size: [160, 600] },
							{ viewport: [1600, 0], size: [300, 600] }
						]}
					/>
				</Col>
			</Row>
		</Grid>
	);
}

Skyscraper.propTypes = {
	user: PropTypes.object,
	path: PropTypes.string,
	location: PropTypes.string,
	class: PropTypes.string,
	noLeftPadding: PropTypes.bool,
	init: PropTypes.bool
};

// Specific to game brick
function Skyscraper2({ user, path, location, noLeftPadding, class: additionalClass, init }) {

	return (
		<Grid class={classnames(styles[location], styles.skyscraper,
			noLeftPadding ? styles.noLeftPadding : '', additionalClass)}
		>
			<Row>
				<Col sm={1} smp={1} md={1} mdp={1} lg={1} xl={1}
					 xlp={1}
				>
					<Ad class={styles.skyscraperAd2Size} user={user} location={location}
						path={path} id={`${location}_${path}`} init={init}
						slots={[
							{ viewport: [0, 0], size: [0, 0] },
							{ viewport: [1100, 0], size: [160, 600] },
							{ viewport: [1600, 0], size: [160, 600] }
						]}
					/>
				</Col>
			</Row>
		</Grid>
	);
}

Skyscraper2.propTypes = {
	user: PropTypes.object,
	path: PropTypes.string,
	location: PropTypes.string,
	class: PropTypes.string,
	noLeftPadding: PropTypes.bool,
	init: PropTypes.bool
};

function Bottom({ user, path, location }) {

	return (
		<Grid class={styles.bottom}>
			<Row>
				<Col sm={1} smp={1} md={1} mdp={1} lg={1} xl={1}
					 xlp={1}
				>
					<Ad class={styles.bottomAdSize} user={user} location={location} path={path} id={`${location}_${path}`}
						slots={[
							{ viewport: [0, 0], size: [0, 0] },
							{ viewport: [960, 0], size: [728, 90] },
							{ viewport: [1280, 0], size: [970, 90] }
						]}
					/>
				</Col>
			</Row>
		</Grid>
	);
}

Bottom.propTypes = {
	user: PropTypes.object,
	path: PropTypes.string,
	location: PropTypes.string
};

function Anchored({ user, component, location, class: additionalClass }) {

	let path = componentToPageMap[component];
	return (
		<div class={classnames(styles.anchored, additionalClass)}>
			<Ad class={styles.anchoredAdSize} fixed user={user} location={location} path={path}
				id={`${location}_${path}`}
				slots={[
					{ viewport: [0, 0], size: [320, 50] },
					{ viewport: [600, 0], size: [468, 60] },
					{ viewport: [800, 0], size: [728, 90] },
					{ viewport: [960, 0], size: [0, 0] }
				]}
			/>
		</div>
	);
}

Anchored.propTypes = {
	user: PropTypes.object,
	path: PropTypes.string,
	location: PropTypes.string,
	class: PropTypes.string,
	component: PropTypes.string
};

function VideoFallback({ user, path, location }) {
	return (
		<Ad class={styles.fallbackAdSize} user={user} location={location} path={path} id={`${location}_${path}`} slots={[
			{ viewport: [0, 0], size: [300, 250] },
			{ viewport: [600, 0], size: [300, 250] },
			{ viewport: [800, 0], size: [300, 250] },
			{ viewport: [960, 0], size: [300, 250] },
			{ viewport: [1280, 0], size: [300, 250] },
			{ viewport: [1600, 0], size: [300, 250] }
		]}
		/>
	);
}

VideoFallback.propTypes = {
	user: PropTypes.object,
	path: PropTypes.string,
	location: PropTypes.string
};

export const TopAd = connect(USER)(memo(Top));
export const GameTopAd = connect(USER)(memo(GameTop));
export const BannerAd = connect(USER)(memo(Banner));
export const SkyscraperAd = connect(USER)(memo(Skyscraper));
export const SkyscraperAd2 = connect(USER)(memo(Skyscraper2));
export const BottomAd = connect(USER)(memo(Bottom));
export const AnchoredAd = connect(USER)(memo(Anchored));
export const VideoFallbackAd = connect(USER)(memo(VideoFallback));
