import { useEffect, useState } from 'preact/hooks';

const useSilentAuth = (user, init) => {
	const [initCalled, setInitCalled] = useState(false);

	useEffect(() => {
		if (user && !initCalled) {
			setInitCalled(true);
			init();
		}
	}, [user, initCalled, init]);
};

export default useSilentAuth;
