import { h } from 'preact';
import styles from './index.css';
import classnames from 'classnames';
import PropTypes from 'prop-types';

export function IconButton({
	class: additionalClass,
	children,
	size = 'default',
	type,
	dark,
	selected,
	disabled,
	onClick,
	'aria-label': ariaLabel,
	'aria-labelledby': ariaLabelledby,
	'aria-controls': ariaControls,
	'aria-expanded': ariaExpanded
}) {
	return (
		<button
			class={
				classnames(
					styles.iconButton,
					styles[size],
					styles[type],
					dark && styles.dark,
					selected && styles.selected,
					disabled && styles.disabled,
					additionalClass
				)
			}
			onClick={onClick}
			aria-label={ariaLabel}
			aria-labelledby={ariaLabelledby}
			aria-controls={ariaControls}
			aria-expanded={ariaExpanded}
		>
			{children}
		</button>
	);
}

IconButton.propTypes = {
	class: PropTypes.string,
	children: PropTypes.any,
	size: PropTypes.string,
	type: PropTypes.string,
	dark: PropTypes.bool,
	selected: PropTypes.bool,
	disabled: PropTypes.bool,
	onClick: PropTypes.func,
	'aria-label': PropTypes.string,
	'aria-labelledby': PropTypes.string,
	'aria-controls': PropTypes.string,
	'aria-expanded': PropTypes.string
};