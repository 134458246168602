import { h } from 'preact';
import Link from 'library/components/util/link';
import { memo } from 'preact/compat';
import styles from './index.css';
import classnames from 'classnames';

/**
 * this component converts text that contains anchor tags to combination of text and <Link> components
 *
 * @param {String} text - text with anchor tags
 * @param {String} location - page location from where html is used
 * @param {String} linkClass - additional class to apply to link
 * @param {Boolean} safe - value that determines if the link is safe for external url
 * @return {(JSX.Element)[]} - combination of text and links, italic, bold, line-breaks
 */
function TextWithHtml({
	text,
	location,
	linkClass: additionalLinkClass,
	safe = true
}) {
	// Do not change this; we should not inject text into document.createElement('div').innerHTML;
	// as that created the opportunity for XSS attacks
	let parser = new DOMParser();
	let document = parser.parseFromString(text, 'text/html');
	let childNodes = document.body.childNodes;
	let nodeList = [];

	childNodes.forEach(node => nodeList.push(node));

	return nodeList.map((node) => {
		switch (node.nodeName) {
			case 'H1':
				return (
					<h1 data-size={node.dataset.size || 'h1'} >
						<TextWithHtml text={node.innerHTML} location={location} safe={safe} />
					</h1>
				);
			case 'H2':
				return (
					<h2 data-size={node.dataset.size || 'h2'} >
						<TextWithHtml text={node.innerHTML} location={location} safe={safe} />
					</h2>
				);
			case 'H3':
				return (
					<h3 data-size={node.dataset.size || 'h3'} >
						<TextWithHtml text={node.innerHTML} location={location} safe={safe} />
					</h3>
				);
			case 'H4':
				return (
					<h4 data-size={node.dataset.size || 'h4'} >
						<TextWithHtml text={node.innerHTML} location={location} safe={safe} />
					</h4>
				);
			case 'H5':
				return (
					<h5 data-size={node.dataset.size || 'h5'} >
						<TextWithHtml text={node.innerHTML} location={location} safe={safe} />
					</h5>
				);
			case 'H6':
				return (
					<h6 data-size={node.dataset.size || 'h6'} >
						<TextWithHtml text={node.innerHTML} location={location} safe={safe} />
					</h6>
				);
			case 'A':
				return (
					<Link
						class={classnames(styles.linkStyles, additionalLinkClass)}
						href={safe ? node.href : node.pathname}
						target={node.target}
						pageLocation={location}
						safe={safe}
						inline
					>
						<TextWithHtml text={node.innerHTML} location={location} safe={safe} />
					</Link>
				);
			case 'I':
				return (
					<i class={styles.italicStyle}>
						<TextWithHtml text={node.innerHTML} location={location} safe={safe} />
					</i>
				);
			case 'B':
				return (
					<b class={styles.boldStyle}>
						<TextWithHtml text={node.innerHTML} location={location} safe={safe} />
					</b>
				);
			case 'BR':
				return (<br />);
			default:
				return node.textContent;
		}
	});
}

export default memo(TextWithHtml);
