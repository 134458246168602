import { h } from 'preact';
import PropTypes from 'prop-types';

export default function Image ({
	class: additionalClass,
	alt = '',
	type = 'image',
	children,
	src,
	...rest
}) {
	if (type === 'background') {
		return (
			<div
				class={additionalClass}
				style={src ? `background-image: url('${src}');` : undefined}
				{...rest}
			> {children} </div>
		);
	}
	if (!alt) {
		throw 'Missing Alt Tag on Image component';
	}
	else {
		return (
			<img
				crossorigin="anonymous"
				class={additionalClass}
				src={src ? src : undefined}
				style={!src ? `visibility: hidden;` : undefined}
				alt={alt}
				{...rest}
			/>
		);
	}
}

Image.propTypes = {
	class: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
	alt: PropTypes.string,
	type: PropTypes.string,
	children: PropTypes.any,
	src: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
	rest: PropTypes.object
};
