import { logError } from 'services/logger';
import { once, onReady } from 'util/index';

export const updateServiceWorker = once( async (user) => {
	try {
		if ('serviceWorker' in navigator && user && !location.host.includes('local')) {
			if (user.segments.includes('serviceWorker')) {
				await onReady();
				await navigator.serviceWorker.register('/service-worker.js');
			}
			else {
				unregister();
			}
		}
	}
	catch (e) {
		logError(e, 'service worker registration');
	}
});

function unregister() {
	navigator.serviceWorker.getRegistrations()
		.then((registrations) => {
			for (let registration of registrations) {
				registration.unregister();
			}
			return null;
		})
		.catch(e => {}); // ignore errors while unregistering
}

