import { h } from 'preact';
import OnboardingTooltip from 'library/components/common/onboarding/tooltip';
import { ONBOARDING, SCREEN_BREAKPOINTS } from 'util/store';
import PropTypes from 'prop-types';
import { connect } from 'unistore/preact';

/**
 * Represents an onboarding tooltip with a delay.
 * @component  OnboardingTooltipWithDelay
 * @param {string} tooltipId - The ID of the tooltip.
 * @param {string} timestamp - The timestamp of the tooltip.
 * @param {string} additionalClass - Additional CSS class for the tooltip.
 * @param {string} arrowPosition - The position of the tooltip arrow.
 * @param {object} tooltips - The tooltips object from the store.
 * @param {object} screenBreakpoints - The screen breakpoints object from the store.
 * @param {string} visibleEqualOrAboveBreakpoint - Tooltip visible upto specific breakpoints.
 * @param {string} visibleEqualOrBelowBreakpoint - Tooltip hidden upto specific breakpoints.
 * @param {string} location - The location of the tooltip using for the telemetry.
 * @returns {JSX.Element} The rendered onboarding tooltip with delay.
 */

const OnboardingTooltipWithDelay = ({ tooltipId, timestamp, class: additionalClass, arrowPosition, tooltips, screenBreakpoints,  visibleEqualOrAboveBreakpoint, visibleEqualOrBelowBreakpoint, location }) => {

	function isTimedTooltipAvailable(){
		let tooltip = tooltips?.[tooltipId];
		if (tooltip) {
			return Date.now() - timestamp >= tooltip.delay;
		}
		return false;
	}
	
	function isVisible(){
		if (visibleEqualOrAboveBreakpoint){
			return screenBreakpoints?.[visibleEqualOrAboveBreakpoint];
		}
		if (visibleEqualOrBelowBreakpoint){
			return !screenBreakpoints?.[visibleEqualOrBelowBreakpoint];
		}
		return true;
	}

	if (isVisible()){
		return (
			<OnboardingTooltip
				tooltipId={tooltipId}
				showTooltip={isTimedTooltipAvailable()}
				arrowPosition={arrowPosition}
				class={additionalClass}
				location={location}
			/>
		);
	}
	return null ;
};

OnboardingTooltipWithDelay.propTypes = {
	tooltipId: PropTypes.string.isRequired,
	timestamp: PropTypes.number.isRequired,
	class: PropTypes.string.isRequired,
	arrowPosition: PropTypes.string.isRequired,
	tooltips: PropTypes.object,
	screenBreakpoints: PropTypes.object.isRequired,
	visibleEqualOrAboveBreakpoint: PropTypes.string,
	visibleEqualOrBelowBreakpoint: PropTypes.string,
	location: PropTypes.string.isRequired
};

const mapStateToProps = ({ [ONBOARDING]: onboarding, [SCREEN_BREAKPOINTS]: screenBreakpoints }) => {
	const tooltips = (onboarding || {}).tooltips;
	return {
		tooltips,
		screenBreakpoints
	};
};

export default connect(mapStateToProps)(OnboardingTooltipWithDelay);