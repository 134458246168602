import { h, toChildArray } from 'preact';
import styles from './index.css';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import DropdownIcon from '!!preact-svg-loader!library/assets/icons/flat/dropdown.svg';
import { useState } from 'preact/hooks';
import { accessibleClick } from 'library/util';

/**
 *
 * @param header
 * @param children
 * @param additionalClass
 * @param open
 * @param onClick
 * @param accessibleHeaderWrapper boolean that indicates if header wrapper should be made accessible, pass false
 * in case header is already accessible for eg. button/link/etc.
 * @param ariaLabel
 * @param ariaLabelledby
 * @return {JSX.Element}
 * @constructor
 */
export default function Accordian({
	header,
	children,
	class: additionalClass,
	headerClass: additionalHeaderClass,
	open,
	onClick,
	accessibleHeaderWrapper = true,
	'aria-label': ariaLabel,
	'aria-labelledby': ariaLabelledby
}) {
	children = toChildArray(children);
	return (
		<div class={classnames(open && styles.open, additionalClass)}>
			{
				accessibleHeaderWrapper ?
					<div
						className={classnames(styles.header, additionalHeaderClass)}
						onClick={onClick}
						role="button"
						tabIndex="0"
						aria-label={ariaLabel}
						aria-labelledby={ariaLabelledby}
						aria-expanded={open ? 'true' : 'false'}
						onKeyPress={accessibleClick(onClick)}
					>
						{header}
					</div> :
					<div
						class={classnames(styles.header, additionalHeaderClass)}
						onClick={onClick}
					>
						{header}
					</div>
			}
			{
				children && children[0] &&
				<div class={styles.children} aria-hidden={open ? 'false' : 'true'} >
					{children}
				</div>
			}
		</div>
	);
}

Accordian.propTypes = {
	header: PropTypes.object,
	children: PropTypes.any,
	class: PropTypes.string,
	headerClass: PropTypes.string,
	open: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
	onClick: PropTypes.func,
	accessibleHeaderWrapper: PropTypes.bool,
	'aria-label': PropTypes.string,
	'aria-labelledby': PropTypes.string
};

/**
 * Renders a list of items
 * @param {Component} children - component(s) rendered inside the AccordionContainer
 * @param {string} class - class name for additional css styles
 * @param {string} title - visible title of the list (user clicks this to open and close the list)
 * @param {string} titleId - id of the list (allows for screen reader to read the title via the titleId reference)
*/
export function AccordionContainer({
	children,
	class: additionalClass,
	title,
	titleId,
	copyClass: copyAdditionalClass
}) {
	const [ isExpanded, setIsExpanded ] = useState(true);
	function toggleExpanded() {
		setIsExpanded(expanded => !expanded);
	}

	return (
		<Accordian
			class={classnames(styles.wrapper, additionalClass)}
			header={
				<div class={classnames(styles.wrapperHeader, isExpanded && styles.active)}>
					<div id={titleId} class={classnames(styles.title, copyAdditionalClass)}>{title}</div>
					<DropdownIcon class={styles.dropdownIcon} />
				</div>
			}
			onClick={toggleExpanded}
			open={isExpanded}
			key={titleId}
			aria-labelledby={titleId}
		>
			{children}
		</Accordian>
	);
}

AccordionContainer.propTypes = {
	children: PropTypes.any,
	class: PropTypes.string,
	title: PropTypes.string,
	titleId: PropTypes.string,
	TitleTag: PropTypes.string,
	copyClass: PropTypes.string
};
