import { h, Fragment } from 'preact';
import styles from './index.css';
import classnames from 'classnames';
import { useEffect } from 'preact/hooks';
import LeftDrawer from 'async?name=leftDrawer!components/common/header/leftDrawer';
import Accordian from 'components/ui/accordian';
import { NavItem, CategoryItem, GameItem, MenuButton } from '../container';
import MenuIcon from 'library/assets/icons/flat/menu.svg';
import SearchIcon from '!!preact-svg-loader!library/assets/icons/flat/search.svg';
import ClubLockIcon from 'library/assets/icons/flat/clubpogolock.svg';
import Image from 'library/components/util/image';
import SearchBar from 'components/common/searchBar';
import OnboardingTooltip from 'library/components/common/onboarding/tooltip';
import Link from 'library/components/util/link';
import ClubLogo from '!!preact-svg-loader!library/assets/clubLogo.svg';
import Logo from '!!preact-svg-loader!library/assets/pogoLogo.svg';
import { CHALLENGEACCESS_FTUE_2_MOBILE_ID, CHALLENGEACCESS_FTUE_2_DESKTOP_ID } from 'library/components/common/portal';
import PropTypes from 'prop-types';
import OnboardingTooltipWithDelay from 'library/components/common/onboarding/tooltipWithDelay';

let leftMenuLocation = 'header';

export default function LeftSection({
	user,
	header,
	search,
	recommendedGames,
	categoriesById,
	isClub,
	showHamburgerDrawer,
	isXLargePlus,
	showCategoryMenu,
	showChallengeMenu,
	showChallengeSubMenu,
	showCommunityMenu,
	showSearchBar,
	onToggleHamburgerDrawer,
	onToggleSearchBar,
	onSearchGame,
	onSearchBarFocus,
	onSearchBarInput,
	onToggleCategoryMenu,
	onToggleChallengeMenu,
	onToggleChallengeSubMenu,
	onToggleCommunityMenu,
	onToggleCategory,
	selectedCategory,
	gamesById
}) {
	useEffect(() => {
		if (showSearchBar) {
			let element = document.querySelector('.' + styles.searchBar + ' input');
			element && element.focus();
		}
	}, [ showSearchBar ]);

	return (
		<Fragment>
			<div id={CHALLENGEACCESS_FTUE_2_MOBILE_ID}>
				<MenuButton
					accessibleWrapper
					class={styles.showBelowXLarge}
					selected={showHamburgerDrawer}
					onClick={onToggleHamburgerDrawer}
				>
					<Image alt={'Menu Icon'} src={MenuIcon} class={styles.menuIcon} />
				</MenuButton>
			</div>
			<Link pageLocation={'header'} hrefKey={'home'}>
				{
					isClub ?
						<ClubLogo class={styles.logo} /> :
						<Logo class={styles.logo} />
				}
			</Link>
			<MenuButton class={styles.showBelowXLarge} selected={showSearchBar} onClick={onToggleSearchBar} accessibleWrapper>
				<SearchIcon
					class={styles.menuIcon}
				/>
			</MenuButton>
			{
				!isXLargePlus &&
					<div class={styles.leftDrawer} >
						<LeftDrawer
							isClub={isClub}
							isOpen={showHamburgerDrawer}
							onClose={onToggleHamburgerDrawer}
							onToggleCategoryMenu={onToggleCategoryMenu}
							onToggleChallengeMenu={onToggleChallengeMenu}
							onToggleCommunityMenu={onToggleCommunityMenu}
							showCategoryMenu={showCategoryMenu}
							showChallengeMenu={showChallengeMenu}
							showCommunityMenu={showCommunityMenu}
							header={header}
							categoriesById={categoriesById}
						/>
					</div>
			}
			{
				user &&
				<Fragment>
					<nav class={styles.nav}>
						<Accordian
							header={
								<NavItem
									class={styles.drawerItem}
									selected={showCategoryMenu}
									isLink={false}
									showDropDownIcon
									primary
								>
									games{' '}
								</NavItem>
							}
							onClick={onToggleCategoryMenu}
							open={showCategoryMenu}
						>
							<div class={styles.categoryMenu}>
								<nav>
									<CategoryItem pageLocation={`${leftMenuLocation}-category`}
										category={{ name: '', id: 'all' }} moreGames isLink
									/>
									{
										(header?.categoryIds || []).map(categoryId => {
											let category = categoriesById[categoryId];
											let activeCategory = categoryId === selectedCategory;
											return (
												<Accordian
													key={categoryId}
													category={category.name}
													header={
														<CategoryItem
															selected={activeCategory}
															category={category}
															isLink
															doNotNavigate
															onClick={onToggleCategory(categoryId)}
															showDropDownIcon
														/>
													}
													open={activeCategory}
												>
													{
														activeCategory &&
														<div className={styles.gameMenu}>
															<CategoryItem category={category} isLink moreGames />
															{
																category.gameCodes.slice(0, 5).map(gameCode =>
																	<GameItem pageLocation={`${leftMenuLocation}-category`} key={gameCode} game={gamesById[gameCode]} />)
															}
														</div>
													}
												</Accordian>
											);
										})
									}
								</nav>
							</div>
						</Accordian>
						{
							user.authenticated &&
							<Fragment>
								<div id={CHALLENGEACCESS_FTUE_2_DESKTOP_ID}>
									<Accordian
										header={
										
											<NavItem
												pageLocation={leftMenuLocation}
												class={styles.drawerItem}
												selected={showChallengeMenu}
												isLink={false}
												showDropDownIcon
												primary
											>
									Challenge{' '}
											</NavItem>
										}
										onClick={onToggleChallengeMenu}
										open={showChallengeMenu}
									>
										<div class={styles.dropdown}>
											<Accordian
												header={
													<NavItem
														selected={showChallengeSubMenu}
														onClick={onToggleChallengeSubMenu}
														doNotNavigate
														isLink={false}
														dropdown
														showDropDownIcon
														underline
													>
													Challenges
													</NavItem>
												}
												open={showChallengeSubMenu}
											>
												<div className={styles.gameMenu}>
													<NavItem pageLocation={`${leftMenuLocation}-challenge`} hrefKey={'challengeCentral'} dropdown underline>
													Challenge Central
													</NavItem>
													<NavItem pageLocation={`${leftMenuLocation}-challenge`} hrefKey={'challengeFilter'} dropdown>
													All Challenges
													</NavItem>
													<NavItem pageLocation={`${leftMenuLocation}-challenge`} hrefKey={'myCollection'} dropdown>
													My Collection
													</NavItem>
												</div>
											</Accordian>
											<NavItem pageLocation={`${leftMenuLocation}-challenge`} hrefKey={'challengeSeason'} useDiv dropdown>
											Pogi Constellations
											</NavItem>
										</div>
									</Accordian>
								</div>
								<Accordian
									header={
										<NavItem
											class={styles.drawerItem}
											selected={showCommunityMenu}
											isLink={false}
											showDropDownIcon
											primary
										>
									community{' '}
										</NavItem>
									}
									onClick={onToggleCommunityMenu}
									open={showCommunityMenu}
								>
									<div class={styles.dropdown}>
										<NavItem safe pageLocation={`${leftMenuLocation}-community`} hrefKey={'forum'} class={styles.categoryItem} external dropdown>pogo forum</NavItem>
										<NavItem safe pageLocation={`${leftMenuLocation}-community`} hrefKey={'blog'} class={styles.categoryItem} external dropdown>pogo blog</NavItem>
										{
											isClub &&
									<NavItem safe pageLocation={`${leftMenuLocation}-community`} hrefKey={'contactUs'} class={styles.categoryItem} external dropdown>contact us</NavItem>
										}
										{
											isClub &&
									<NavItem safe pageLocation={`${leftMenuLocation}-community`} hrefKey={'troubleshooting'} class={styles.categoryItem} external dropdown>troubleshooting</NavItem>
										}
										{
											isClub &&
									<NavItem safe pageLocation={`${leftMenuLocation}-community`} hrefKey={'faq'} class={styles.categoryItem} external dropdown>FAQ</NavItem>
										}
									</div>
								</Accordian>
								<div class={styles.joinClub}>
									<NavItem hrefKey={'clubPogo'} pageLocation={leftMenuLocation}  primary>
										<Image alt={'Club Lock Icon'} src={ClubLockIcon} class={classnames(styles.drawerIcon, styles.headerIcon)} /> Join Club
									</NavItem>
									{
										user.authLevel === 'FREE' &&
										<OnboardingTooltipWithDelay
											location={'post registration'}
											visibleEqualOrAboveBreakpoint={'isXLargePlus'}
											arrowPosition={'top'}
											tooltipId={'whySubscribe'}
											class={styles.joinClubTooltip}
											timestamp={new Date(user?.signupDate).getTime()}
										/>
									}
								</div>
							</Fragment>
						}
					</nav>
					<div
						class={classnames(styles.searchBar, !showSearchBar && styles.showAboveXLarge)}
					>
						{!search && <OnboardingTooltip showTooltip class={styles.tooltip} tooltipId={'search'} location={'navigation'} /> }
						<Accordian
							header={
								<SearchBar
									value={search}
									placeholder={'Search for games'}
									maxlength={50}
									onFocus={onSearchBarFocus}
									onSearch={onSearchGame}
									onInput={onSearchBarInput}
									class={styles.searchBarContainer}
								/>}
							class={styles.searchHeader}
							accessibleHeaderWrapper={false}
							open={search}
						>
							<div class={styles.recommendedGames}>
								{
									(showSearchBar && recommendedGames) &&
									<Fragment>
										{
											recommendedGames.length ?
												recommendedGames.map(gameCode =>
													(
														<GameItem
															pageLocation={'header-search'}
															key={gameCode}
															game={gamesById[gameCode]}
														/>
													)
												) :
												(
													<NavItem
														pageLocation={'header-search'}
														onClick={onSearchGame}
														isLink={false}
														dropdown
													>
														Search Games for "{search}"?
													</NavItem>
												)
										}
									</Fragment>
								}
							</div>
						</Accordian>
					</div>
				</Fragment>
			}
		</Fragment>
	);
}

LeftSection.propTypes = {
	user: PropTypes.object,
	header: PropTypes.object,
	search: PropTypes.string,
	recommendedGames: PropTypes.array,
	categoriesById: PropTypes.object,
	isClub: PropTypes.bool,
	showHamburgerDrawer: PropTypes.bool,
	isXLargePlus: PropTypes.bool,
	showCategoryMenu: PropTypes.bool,
	showChallengeMenu: PropTypes.bool,
	showChallengeSubMenu: PropTypes.bool,
	showCommunityMenu: PropTypes.bool,
	showSearchBar: PropTypes.bool,
	onSearchGame: PropTypes.func,
	onSearchBarFocus: PropTypes.func,
	onSearchBarInput: PropTypes.func,
	onToggleHamburgerDrawer: PropTypes.func,
	onToggleCategoryMenu: PropTypes.func,
	onToggleSearchBar: PropTypes.func,
	onToggleChallengeMenu: PropTypes.func,
	onToggleChallengeSubMenu: PropTypes.func,
	onToggleCommunityMenu: PropTypes.func,
	onToggleCategory: PropTypes.func,
	selectedCategory: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
	gamesById: PropTypes.object
};
