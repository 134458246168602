import { h } from 'preact';
import { IconButton } from 'library/components/ui/iconButton2';
import CloseIcon from '!!preact-svg-loader!library/assets/icons/flat/close.svg';
import PropTypes from 'prop-types';

export function CloseButton({
	class: additionalClass,
	size,
	type,
	dark,
	onClick,
	'aria-label': ariaLabel,
	'aria-labelledby': ariaLabelledby,
	'aria-controls': ariaControls,
	'aria-expanded': ariaExpanded
}) {
	return (
		<IconButton
			class={additionalClass}
			onClick={onClick}
			size={size}
			type={type}
			dark={dark}
			aria-label={ariaLabel}
			aria-labelledby={ariaLabelledby}
			aria-controls={ariaControls}
			aria-expanded={ariaExpanded}
		>
			<CloseIcon />
		</IconButton>
	);
}

CloseButton.propTypes = {
	class: PropTypes.string,
	size: PropTypes.string,
	type: PropTypes.string,
	dark: PropTypes.bool,
	onClick: PropTypes.func,
	'aria-label': PropTypes.string,
	'aria-labelledby': PropTypes.string,
	'aria-controls': PropTypes.string,
	'aria-expanded': PropTypes.string
};