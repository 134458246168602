export const CONNECTION_STATE_INITIAL = 1;
export const CONNECTION_STATE_CONNECTED = 2;
export const CONNECTION_STATE_UNRECOVERABLE = 3;
export const CONNECTION_STATE_INACTIVITY_DISCONNECTED = 4;
export const CONNECTION_STATE_FORCE_DISCONNECTED = 5;

let socialSdk;

export function getSocialSdk() {
	return socialSdk;
}

export function setSocialSdk(sdk) {
	socialSdk = sdk;
}

export function getFriendsInGameCount(presenceByIds, gameCode) {
	if (presenceByIds && gameCode) {
		return Object.values(presenceByIds).reduce((counter, { richPresence }) => {
			if (richPresence && richPresence.game === gameCode) {
				return counter += 1;
			}
			return counter;
		}, 0);
	}
	return 0;
}
