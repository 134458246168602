import { h, Component, Fragment } from 'preact';
import { connect } from 'unistore/preact';
import styles from './index.css';
import pure from 'library/hoc/pureComponent';
import Link from 'library/components/util/link';
import classnames from 'classnames';
import { scrollToTop } from 'util/index';
import { isUsa } from 'services/privacy';
import { Grid } from 'library/components/ui/grid';
import { dispatchEvent  } from '@pogo-internal/events/eventManager';
import { CLICK_LOCATION } from '@pogo-internal/events/attributes';
import { CLICK } from '@pogo-internal/events/events';
import { ONBOARDING, INTERSTITIALS, CLOSED, USER, ROUTE, ERROR, store, SHOW_CONSENT_BANNER } from 'util/store';
import PropTypes from 'prop-types';

export let actions = store => ({
	openInterstitial({ [ROUTE]: route, [ONBOARDING]: onboarding }) {
		store.setState({
			[ONBOARDING]: {
				...onboarding,
				[INTERSTITIALS]: {
					...onboarding.interstitials,
					[route.component]: {
						...onboarding.interstitials[route.component],
						[CLOSED]: false
					}
				}
			}
		});
	}
});

const mapStateToProps = ({ [ROUTE]: route, [ONBOARDING]: onboarding, [USER]: user, [ERROR]: error }) => {
	const authenticated = user && user.authenticated;
	return {
		interstitial: authenticated && route && onboarding && onboarding !== 'loading' && onboarding.interstitials && onboarding.interstitials[route.component] && !['home','challengeCentral','pregame'].includes(route.component),
		authenticated,
		user,
		error
	};
};

@pure
export class Footer extends Component {
	constructor(props) {
		super(props);
		this.onOpenInterstitial = this.onOpenInterstitial.bind(this);
	}

	onOpenInterstitial() {
		scrollToTop(100);
		this.props.openInterstitial();
		dispatchEvent(CLICK, { [CLICK_LOCATION]: 'onboarding-footer-replay' });
	}

	onClickCookiePreferences() {
		store.setState({ [SHOW_CONSENT_BANNER]: true });
	}

	render({
		user,
		error,
		class: additionalClass,
		interstitial,
		authenticated
	}) {
		if (!user) {
			return;
		}
		let date = new Date();
		let year = date.getFullYear();
		let showInterstitial = authenticated && interstitial;
		return (
			<footer class={classnames(styles.footer, additionalClass)}>
				<Grid>
					{showInterstitial && <Fragment><span class={styles.onboardingTrigger} onClick={this.onOpenInterstitial}>{'Replay the Tour'}</span><span>{' '}-{' '}</span></Fragment >}
					{!error && <Link pageLocation={'footer'} hrefKey={'home'}>Home</Link>}{!error && <span>{' '}-{' '}</span>}
					<Link safe underline pageLocation={'footer'} hrefKey={'userAgreement'} target={'_blank'}>User Agreement</Link>{' '}-{' '}
					<Link safe underline pageLocation={'footer'} hrefKey={'privacy'} target={'_blank'}>Privacy & Cookie Policy</Link>{' '}-{' '}
					{isUsa() && <Fragment><Link underline pageLocation={'footer'} hrefKey={'personalInformation'} target={'_blank'}>Do Not Sell My Personal Information</Link><span>{' '}-{' '}</span></Fragment>}
					<Link safe underline pageLocation={'footer'} hrefKey={'serviceUpdate'} target={'_blank'}>Online Service Updates</Link>{' '}-{' '}
					<Link safe underline pageLocation={'footer'} hrefKey={'termsOfSale'} target={'_blank'}>Terms of Sale</Link>{' '}-{' '}
					<Link safe underline pageLocation={'footer'} hrefKey={'legal'} target={'_blank'}>Legal</Link>{' '}-{' '}
					{!user.underAge && <Fragment><div id="teconsent" onClick={this.onClickCookiePreferences} /><span>{' '}-{' '}</span></Fragment>}
					<Link underline pageLocation={'footer'} hrefKey={'feedback'} target={'_blank'}>Give Feedback</Link>{' '}-{' '}
					<Link safe underline pageLocation={'footer'} hrefKey={'iabCompliance'} target={'_blank'}>IAB</Link>
					<div class={styles.copyright}>&copy; 2018-{year}{' '}
						<Link safe pageLocation={'footer'} hrefKey={'ea'} target={'_blank'}>
							Electronic Arts Inc.
						</Link>
					</div>
				</Grid>
			</footer>
		);
	}
}

Footer.propTypes = {
	user: PropTypes.object,
	error: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
	class: PropTypes.string,
	interstitial: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
	authenticated: PropTypes.bool,
	openInterstitial: PropTypes.func
};

export default connect(mapStateToProps, actions)(Footer);