import { h } from 'preact';
import classnames from 'classnames';
import styles from './index.css';
import PropTypes from 'prop-types';

export default function Loading({
	flavor,
	size,
	position,
	strokeWidth,
	stopColor,
	class: additionalClasses
}) {
	let svg = (<svg xmlns="http://www.w3.org/2000/svg" class={classnames(styles.loadingIcon, additionalClasses)} width={size} height={size} viewBox="0 0 231 231">
		<defs>
			<linearGradient id="loadingIcon" gradientUnits="objectBoundingBox" x1="0" y1="1" x2="1" y2="0">
				<stop offset="15%" stop-color={stopColor} stop-opacity="0" />
				<stop offset="85%" stop-color={stopColor} />
			</linearGradient>
		</defs>
		<g
			fill="none"
			stroke-width={strokeWidth}
			transform="translate(115.5,115.5)"
		>
			<path
				d="M 0,-100 A 100,100 0 0,1 86.6,-50 M 86.6,-50 A 100,100 0 0,1 86.6,50
					M 86.6,50 A 100,100 0 0,1 0,100 M 0,100 A 100,100 0 0,1 -86.6,50"
				stroke={stopColor}
			/>
			<path
				d="M -86.6,50 A 100,100 0 0,1 -86.6,-50 M -86.6,-50 A 100,100 0 0,1 0,-100"
				stroke="url(#loadingIcon)"
			/>
		</g>
	</svg>);

	if (flavor) {
		return (
			<div class={classnames(styles[flavor], styles[position])} aria-label="Loading" >
				{svg}
			</div>
		);
	}
	return svg;
}

Loading.defaultProps = {
	flavor: undefined,
	size: 67,
	strokeWidth: 30,
	stopColor: '#FFF'
};

Loading.propTypes = {
	flavor: PropTypes.string,
	size: PropTypes.number,
	strokeWidth: PropTypes.number,
	stopColor: PropTypes.string,
	class: PropTypes.string,
	position: PropTypes.string
};
