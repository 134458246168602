import { h } from 'preact';

export default function silentAuth(target) {
	if (target.prototype && target.prototype.render) {
		target.prototype.oldcomponentDidMount = target.prototype.componentDidMount;
		target.prototype.componentDidMount = componentDidMount;
		target.prototype.oldcomponentDidUpdate = target.prototype.componentDidUpdate;
		target.prototype.componentDidUpdate = componentDidUpdate;
	}
}

function componentDidMount() {
	if (this.props.user) {
		// console.log(this.constructor.name + ' mount');
		this.init();
	}
	this.oldcomponentDidMount && this.oldcomponentDidMount();
}

function componentDidUpdate(prevProps, prevState) {
	if (this.props.user && !prevProps.user) {
		// console.log(this.constructor.name + ' update');
		this.init();
	}
	this.oldcomponentDidUpdate && this.oldcomponentDidUpdate(prevProps, prevState);
}