import { h } from 'preact';
import classnames from 'classnames';
import styles from './index.css';
import PropTypes from 'prop-types';
import LoadingIcon from 'library/components/common/loading';
import ErrorIcon from 'library/assets/icons/detailed/error.svg';
import SuccessIcon from 'library/assets/icons/detailed/subscribed.svg';
import Image from 'library/components/util/image';

export default function Button({
	disabled = false,
	size = 'default',
	type = 'primary',
	status,
	onClick = () => { },
	class: additionalClass,
	children = 'text',
	'aria-label': ariaLabel,
	'aria-labelledby': ariaLabelledby,
	'aria-describedby': ariadescribedby,
	'aria-controls': ariaControls,
	'aria-expanded': ariaExpanded,
	noMargin = false, // TODO: preferably we can remove the default margin from <Button /> and let component calling <Button /> set its own spacing
	icon
}) {
	status = status || type; // TODO: convert any component to use "type" as a status to use "status" instead; remove checkmark case as well
	switch (status) {
		case 'checkmark':
		case 'success':
			icon = (
				<Image class={styles.icon} src={SuccessIcon} alt={'success icon'} />
			);
			disabled = true;
			break;
		case 'loading':
			icon = (
				<LoadingIcon class={classnames(styles.icon, styles.loadingIcon)} flavor={'cubic'} />
			);
			disabled = true;
			break;
		case 'error':
			icon = (
				<Image class={styles.icon} src={ErrorIcon} alt={'error icon'} />
			);
			disabled = true;
			break;
		case 'default':
		default:
			if (icon) icon = (
				<Image class={styles.icon} src={icon} alt={'icon'} />
			);
	}
	return (
		<button
			class={classnames(
				disabled && styles.disabled,
				styles[size],
				styles[type],
				styles.button,
				additionalClass,
				noMargin && styles.noMargin
			)}
			onClick={onClick}
			aria-label={ariaLabel}
			aria-labelledby={ariaLabelledby}
			aria-describedby={ariadescribedby}
			aria-controls={ariaControls}
			aria-expanded={ariaExpanded}
			aria-disabled={disabled}
		>
			<div class={styles.inner}>
				{icon}
				{children}
			</div>
		</button>
	);
}

Button.propTypes = {
	disabled: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
	size: PropTypes.string,
	type: PropTypes.string,
	onClick: PropTypes.func,
	class: PropTypes.string,
	children: PropTypes.any,
	status: PropTypes.string,
	'aria-label': PropTypes.string,
	'aria-labelledby': PropTypes.string,
	'aria-describedby': PropTypes.string,
	'aria-controls': PropTypes.string,
	'aria-expanded': PropTypes.string,
	noMargin: PropTypes.bool,
	icon: PropTypes.string
};
