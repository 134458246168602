import { insertBefore, removeElement } from 'util/index';
import { get } from 'services/restApi';
import { logError } from './logger';
import { BODY, GAMES_BY_ID, SEO, SORTED_GAME_CODES, STATUS, store } from 'util/store';
import SocialImage from 'assets/misc/social.jpg';
import { getURL } from './urlService';

function insertBeforeManifest(tag, keys) {
	insertBefore(document.querySelector('link[rel="manifest"]'), tag, keys);
}

export function getSeoTags(path) {
	return get(`/server/rest/seo/tags?path=${path}`);
}

export async function addSeoTags(route, url) {
	try {
		if (url.includes('?')) {
			url = url.split('?')[0];
		}

		let tags = await getSeoTags(url);
		store.setState({ [SEO]: tags });

		document.title = tags.title || route.title;
		let currentPageHost = window.location.protocol + '//' + window.location.hostname;

		removeElement(document.querySelector('meta[name="robots"]'));
		removeElement(document.querySelector('meta[name="description"]'));
		removeElement(document.querySelector('link[rel="canonical"]'));
		removeElement(document.querySelector('meta[property="og:title"]'));
		removeElement(document.querySelector('meta[property="og:url"]'));
		removeElement(document.querySelector('meta[property="og:description"]'));
		removeElement(document.querySelector('meta[property="og:type"]'));
		removeElement(document.querySelector('meta[property="og:locale"]'));
		removeElement(document.querySelector('meta[property="og:image"]'));
		removeElement(document.querySelector('meta[property="og:image:alt"]'));
		removeElement(document.querySelector('meta[property="og:image:secure_url"]'));
		removeElement(document.querySelector('meta[property="og:image:url"]'));
		removeElement(document.querySelector('meta[property="og:image:width"]'));
		removeElement(document.querySelector('meta[property="og:image:height"]'));
		removeElement(document.querySelector('meta[property="og:site_name"]'));
		removeElement(document.querySelector('meta[property="fb:app_id"]'));
		removeElement(document.querySelector('meta[property="twitter:card"]'));
		removeElement(document.querySelector('meta[property="twitter:site"]'));

		if (route.seo) {
			insertBeforeManifest('meta', { name: 'description', content: tags.metaDescription || route.description });
			insertBeforeManifest('link', { rel: 'canonical', href: location.protocol + '//' + location.host + location.pathname });
			insertBeforeManifest('meta', { property: 'og:title', content: document.title });
			insertBeforeManifest('meta', { property: 'og:url', content: location.protocol + '//' + location.host + location.pathname });
			insertBeforeManifest('meta', { property: 'og:description', content: tags.metaDescription || route.description });
			insertBeforeManifest('meta', { property: 'og:type', content: 'website' });
			insertBeforeManifest('meta', { property: 'og:locale', content: 'en_US' });
			insertBeforeManifest('meta', { property: 'og:image', content: currentPageHost + SocialImage });
			insertBeforeManifest('meta', { property: 'og:image:alt', content: 'social thumbnail' });
			insertBeforeManifest('meta', { property: 'og:image:secure_url', content: currentPageHost + SocialImage });
			insertBeforeManifest('meta', { property: 'og:image:url', content: currentPageHost + SocialImage });
			insertBeforeManifest('meta', { property: 'og:image:width', content: '1200' });
			insertBeforeManifest('meta', { property: 'og:image:height', content: '630' });
			insertBeforeManifest('meta', { property: 'og:site_name', content: 'Pogo.com' });
			insertBeforeManifest('meta', { property: 'fb:app_id', content: '294400680281' });
			insertBeforeManifest('meta', { property: 'twitter:card', content: 'summary' });
			insertBeforeManifest('meta', { property: 'twitter:site', content: '@Pogo' });
		}
		else {
			insertBeforeManifest('meta', { name: 'robots', content: 'noindex, nofollow' });
		}

		const organization = {
			'@type': 'Organization',
			'@id': 'https://www.ea.com/#organization',
			name: 'Electronic Arts',
			url: 'https://www.ea.com/',
			sameAs: [
				'https://www.facebook.com/EA',
				'https://www.instagram.com/ea/',
				'https://www.linkedin.com/company/electronic-arts/',
				'https://twitter.com/EA'
			],
			logo: {
				'@type': 'ImageObject',
				'@id': 'https://www.ea.com/#logo',
				url: 'https://pbs.twimg.com/profile_images/1022579393347051520/wio5UT1M_400x400.jpg',
				width: 400,
				height: 400,
				caption: 'Electronic Arts'
			},
			image: {
				'@id': 'https://www.ea.com/#logo'
			}
		};

		const website = {
			'@type': 'WebSite',
			'@id': `${currentPageHost}/#website`,
			url: `${currentPageHost}/`,
			name: 'Pogo',
			publisher: {
				'@id': 'https://www.ea.com/#organization'
			},
			potentialAction: {
				'@type': 'SearchAction',
				target: `${currentPageHost}/search?query={search_term_string}`,
				'query-input': 'required name=search_term_string'
			}
		};

		const webpage = {
			'@type': 'WebPage',
			'@id': `${currentPageHost + url}/#webpage`,
			url: `${currentPageHost + url}`,
			inLanguage: 'en',
			name: tags.title || route.title,
			isPartOf: {
				'@id': `${currentPageHost}/#website`
			},
			primaryImageOfPage: {
				'@type': 'ImageObject',
				'@id': `${currentPageHost + url}/#primaryimage`,
				url: currentPageHost + (await getPrimaryImage(route))
			},
			datePublished: '2006-08-20T18:57:44+00:00',
			dateModified: '2020-01-20T14:11:40+00:00',
			description: tags.metaDescription || route.description
		};

		let structuredData;

		if (route.component === 'home') {
			structuredData = {
				'@context': 'https://schema.org',
				'@graph': [
					organization,
					website,
					webpage
				]
			};
		}

		if (route.component === 'category') {
			await categoryStoreUpdate(); // returns a promise that resolves when category related data is set in store in category route
			const gameCodes = store.getState()[BODY][SORTED_GAME_CODES], gamesById = store.getState()[GAMES_BY_ID];
			
			let itemList = {
				'@type': 'ItemList',
				numberOfItems: gameCodes.length,
				name: tags.title || route.title,
				url: `${currentPageHost + url}`,
				itemListElement: gameCodes.slice(0, 20).map((gameCode, index) => ({
					'@type': 'ListItem',
					position: index + 1,
					name: gamesById[gameCode].shortName,
					description: gamesById[gameCode].shortDescription,
					image: currentPageHost + require(`assets/games/${gameCode}/eventTile.jpg`),
					url: currentPageHost + getURL(`pregame/${gameCode}`)
				}))
			};

			structuredData = {
				'@context': 'https://schema.org',
				'@graph': [
					webpage,
					itemList
				]
			};
		}

		if (route.component === 'pregame' || route.component === 'game') {
			await gameStoreUpdate(); // returns a promise that resolves when category related data for the game is set in store in pregame route
			const gamesById = store.getState()[GAMES_BY_ID];
			
			let videoGame = {
				'@type': 'VideoGame',
				name: tags.title || route.title,
				description: tags.metaDescription || route.description,
				image: currentPageHost + require(`assets/games/${route.gameCode}/eventTile.jpg`),
				url: `${currentPageHost + url}`,
				playMode: 'SinglePlayer',
				gamePlatform: [
					'Mobile game',
					'Mac game',
					'PC game'
				],
				genre: (gamesById[route.gameCode] ?? {}).categories,
				author: {
					'@type': 'Organization',
					'@id': 'https://www.pogo.com/#organization'
				},
				publisher: {
					'@type': 'Organization',
					'@id': 'https://www.pogo.com/#organization'
				},
				inLanguage: [
					'English'
				],
				applicationCategory: 'Game',
				operatingSystem: [
					'Android',
					'macOS',
					'Windows',
					'Unix-like'
				]
			};

			structuredData = {
				'@context': 'https://schema.org',
				'@graph': [
					webpage,
					videoGame
				]
			};
		}

		structuredData && insertStructuredData(structuredData);
	}
	catch (e) {
		logError(e, 'seo - addSeoTags');
	}
}

async function categoryStoreUpdate() {
	return new Promise(resolve => {
		const unsubscribe = store.subscribe(onStoreStateChange);
		function onStoreStateChange(state) {
			if (state[BODY] && state[BODY][SORTED_GAME_CODES]) {
				unsubscribe();
				resolve();
			}
		}
	});
}

async function gameStoreUpdate() {
	return new Promise(resolve => {
		const unsubscribe = store.subscribe(onStoreStateChange);
		function onStoreStateChange(state) {
			if (state[BODY] && state[BODY][STATUS]) {
				unsubscribe();
				resolve();
			}
		}
	});
}

function insertStructuredData(structuredData) {
	let structuredDataScript = document.querySelector('script[id="structuredData"]');
	if (!structuredDataScript) {
		insertBeforeManifest('script', { type: 'application/ld+json', id: 'structuredData' });
		structuredDataScript = document.querySelector('script[id="structuredData"]');
	}
	structuredDataScript.text = JSON.stringify(structuredData);
}

/**
 * returns an object containing keys url, height, width of the primary image of the page (spotlight).
 * if image is not available or comes via an api then return default image
 * update: dynamic import with lazy reduces main bundle size by 10kb versus cjs require
 **/
async function getPrimaryImage(route) {
	let img;
	try {
		switch (route.component) {
			case 'category':
				img = await import(

					/* webpackMode: "lazy-once" */
					'assets/categories/' + route.categoryId + '/spotlight.jpg')
					.then(res => res.default);
				break;
			case 'pregame':
				img = await import(

					/* webpackMode: "lazy-once"  */
					'assets/games/' + route.gameCode + '/spotlightGame.jpg')
					.then(res => res.default);
				break;
			default:
				img = await import(

					/* webpackMode: "eager"  */
					`assets/misc/pogo-logo.jpg`)
					.then(res => res.default);
				break;
		}
	}
	catch (e) {
		img  = await import(

			/* webpackMode: "eager"  */
			`assets/misc/pogo-logo.jpg`)
			.then(res => res.default);
	}

	return img;
}