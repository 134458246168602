const sizes = {
	sm: '--enhance-small-plus',
	smp: '--enhance-small-plus-plus',
	md: '--enhance-medium-plus',
	mdp: '--enhance-medium-plus-plus',
	lg: '--enhance-large-plus',
	xl: '--enhance-xlarge-plus',
	xlp: '--enhance-xlarge-plus-plus'
};

const breakpoints = {
	'--enhance-small-plus': 360,
	'--enhance-small-plus-plus': 480,
	'--enhance-medium-plus': 600,
	'--enhance-medium-plus-plus': 800,
	'--enhance-large-plus': 960,
	'--enhance-xlarge-plus': 1280,
	'--enhance-xlarge-plus-plus': 1600,
	generateMediaQuery: () => {
		let objectQuery = {};
		Object.entries(breakpoints).map(entry => {
			if (typeof entry[1] === 'number') {
				objectQuery[entry[0]] = `screen and (width >= ${entry[1]}px)`;
			}
		});
		return { customMedia: objectQuery };
	},
	getCurrentBreakpoint() {
		const width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
		const currentBreakpoint = Object.entries(sizes).reverse().find(size => width >= breakpoints[size[1]]);
		return (currentBreakpoint && currentBreakpoint[0]) || false;
	}
};

module.exports =  breakpoints;