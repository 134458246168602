import { trackLCP, trackCLS, trackINP, setSource } from '@pogo-internal/web-vitals';
import { dispatchEvent } from '@pogo-internal/events/eventManager';
import { TIMING } from '@pogo-internal/events/events';
import { CATEGORY, LABEL, TYPE, VALUE } from '@pogo-internal/events/attributes';

// ------------------------------------------------ Web vital tracking ------------------------------------------------

export function trackWebvitals() {
	setSource('venus');
	trackLCP(true);
	trackCLS(true);
	trackINP(true);
}


// --------------------------------------------- Game performance tracking ---------------------------------------------

let performanceAvailable = !!window.performance && performance.mark && performance.getEntriesByName
	&& performance.measure && performance.clearMarks && performance.clearMeasures;

export function setPerformanceMark(name) {
	if (performanceAvailable) {
		clearMarks(name);
		performance.mark(name);
	}
}

export function sendMeasurement(start, end, measureName = 'Game Performance', label, threshold) {
	if (performanceAvailable) {
		start = performance.getEntriesByName(start, 'mark').length ? start : undefined;
		end = performance.getEntriesByName(end, 'mark').length ? end : undefined;
		clearMeasure(measureName);
		let measure = performance.measure(measureName, start, end) || {};
		// In case of measure higher than threshold, set this measure as an anomaly and limit the result
		if (threshold) {
			let seconds = Math.round(measure.duration) / 1000;
			measureName = seconds > threshold ? measureName + '-anomaly' : measureName;
		}
		dispatchEvent(TIMING, { [CATEGORY]: measureName, [TYPE]: `${start ? start :
			'Page Navigation'}-${end ? end : 'Present'}`, [VALUE]: Math.round(measure.duration / 1000), [LABEL]: label });
	}
}

export function clearMarks(name) {
	if (performanceAvailable && performance.getEntriesByName(name, 'mark').length) {
		performance.clearMarks(name);
	}
}

function clearMeasure(name) {
	if (performanceAvailable && performance.getEntriesByName(name, 'measure').length) {
		performance.clearMeasures(name);
	}
}