/* globals CLIENT_VERSION, POGO_ENVIRONMENT */

import { addEventListener } from '@pogo-internal/events/eventManager';
import { store, GA4_CLIENT_ID, GA4_SESSION_ID, URL, ROUTE } from 'util/store';
import { getQueryStringParams } from 'library/util';
import {
	PAGE_VIEW,
	CLICK,
	SELECT_PROMOTION,
	VIEW_PROMOTION,
	EARN_VIRTUAL_CURRENCY,
	SPEND_VIRTUAL_CURRENCY,
	SCROLL,
	SEARCH,
	REGISTRATION,
	TUTORIAL_BEGIN,
	TUTORIAL_COMPLETE,
	ERROR,
	ERROR_PAGE,
	WEB_VITALS,
	TIMING,
	SELECT_GEM_PACK,
	SUBSCRIPTION,
	CANCELATION,
	GAME_START,
	GAME_OVER,
	GAME_EXIT,
	LOADING_AD,
	INTERMISSION_AD,
	INTERMISSION_COUNTDOWN_START,
	SIGN_IN,
	COPPA,
	TOOLTIP,
	COMPLETE_CHALLENGE,
	SDK_STORE_AND_POPUP, GAME_EVENT
} from '@pogo-internal/events/events';
import {
	PAGE_PATH,
	PAGE_TYPE,
	CLICK_LOCATION,
	DESTINATION_URL,
	PROMOTION_ID,
	VIRTUAL_CURRENCY_NAME,
	VIRTUAL_CURRENCY_VALUE,
	ITEM_ID,
	ITEM_NAME,
	ITEM_CATEGORY,
	ITEM_VALUE,
	ITEM_LOCATION,
	SCROLL_PERCENT,
	SEARCH_TERM,
	TUTORIAL_ID,
	SOURCE,
	MESSAGE,
	STACK,
	STATUS,
	METRIC,
	TARGET,
	VALUE,
	CATEGORY,
	TYPE,
	LABEL,
	PRICE,
	QUANTITY,
	SUBSCRIPTION_ID,
	SUBSCRIPTION_VALUE,
	SUBSCRIPTION_CURRENCY,
	CANCELATION_STEP,
	ROUND_COUNT,
	GAME_CODE,
	PHASE,
	AD_TYPE,
	INT_NUMBER,
	SUBSCRIPTION_NAME,
	SUBSCRIPTION_LOCATION,
	SUBSCRIPTION_COUPON,
	SUBSCRIPTION_TAX,
	SEND_SEGMENTS,
	ACTION,
	CHALLENGE_TYPE,
	CHALLENGE_ID,
	TOOLTIP_NAME,
	TOOLTIP_OPERATION,
	TOOLTIP_LOCATION
} from '@pogo-internal/events/attributes';
import { once } from 'util/index';
import { isExternal } from 'services/urlService';

window.dataLayer = window.dataLayer || [];

const TAG_ID = 'G-Z53CS8CSXY';

// eslint-disable-next-line prefer-rest-params
function gtag(){window.dataLayer.push(arguments);}

export const enableGa4 = once((user) => {
	let config = {
		send_page_view: false
	};
	if (user.authenticated) {
		config.user_id = user.id;
	}

	let ga4ClientId = getQueryStringParams().ga4ClientId;
	let ga4SessionId = getQueryStringParams().ga4SessionId;

	if (ga4ClientId && ga4SessionId) {
		config.client_id = ga4ClientId;
		config.session_id = ga4SessionId;
	}

	gtag('js', new Date());
	gtag('config', TAG_ID, config);
	gtag('get', TAG_ID, 'client_id', ( clientId ) => {
		store.setState({ [GA4_CLIENT_ID]: clientId });
	});
	gtag('get', TAG_ID, 'session_id', ( sessionId ) => {
		store.setState({ [GA4_SESSION_ID]: sessionId });
	});

	addEventListener(PAGE_VIEW, ({ [PAGE_PATH]: path, [PAGE_TYPE]: type, [SEND_SEGMENTS]: sendSegments }) => {
		gtag('event', 'page_view', {
			...getCustomParams(user),
			page_location: window.location.origin + path,
			page_type: type
		});

		if (sendSegments && user?.segments) {
			concatenateStrings(user.segments).forEach(segment => gtag('event', 'segment', {
				...getCustomParams(user),
				segment
			}));
		}

	});

	addEventListener(REGISTRATION, () => {
		gtag('event', 'sign_up', getCustomParams(user));
	});

	addEventListener(SIGN_IN, () => {
		gtag('event', 'login', getCustomParams(user));
	});

	addEventListener(CLICK, ({ [CLICK_LOCATION]: clickLocation, [DESTINATION_URL]: destinationUrl,
							 [GAME_CODE]: gameCode, [CHALLENGE_TYPE]: challengeType }) => {
		gtag('event', 'click', {
			...getCustomParams(user, gameCode),
			click_location: clickLocation,
			click_type: destinationUrl === undefined ? 'passive' : 'navigation',
			origin_url: `${sanitize(store.getState()[URL])}`,
			destination_url: `${sanitize(destinationUrl || 'none')}`,
			...(challengeType && { challenge_type: challengeType })
		});
	});

	addEventListener(SELECT_PROMOTION, ({ [PROMOTION_ID]: id }) => {
		gtag('event', 'select_promotion', {
			...getCustomParams(user),
			promotion_id: id
		});
	});

	addEventListener(VIEW_PROMOTION, ({ [PROMOTION_ID]: id }) => {
		gtag('event', 'view_promotion', {
			...getCustomParams(user),
			promotion_id: id
		});
	});

	addEventListener(EARN_VIRTUAL_CURRENCY, ({ [VIRTUAL_CURRENCY_NAME]: name, [VIRTUAL_CURRENCY_VALUE]: val }) => {
		gtag('event', 'earn_virtual_currency', {
			...getCustomParams(user),
			virtual_currency_name: name,
			value: val
		});
	});

	addEventListener(SPEND_VIRTUAL_CURRENCY, ({ [ITEM_ID]: id, [ITEM_NAME]: name, [ITEM_CATEGORY]: category, [ITEM_VALUE]: value, [ITEM_LOCATION]: itemLocation }) => {
		gtag('event', 'spend_virtual_currency', {
			...getCustomParams(user),
			value,
			virtual_currency_name: 'Gems',
			item_id: id,
			item_name: name,
			item_category: category,
			item_location: itemLocation // TODO: validate with Sandra
		});
	});

	addEventListener(SCROLL, ({ [SCROLL_PERCENT]: scroll, [PAGE_PATH]: path }) => {
		gtag('event', 'scroll', {
			...getCustomParams(user),
			page_location: window.location.origin + path,
			scroll_percent: scroll
		});
	});

	addEventListener(SEARCH, ({ [SEARCH_TERM]: searchTerm }) => {
		gtag('event', 'search', {
			...getCustomParams(user),
			search_term: searchTerm
		});
	});

	addEventListener(TUTORIAL_BEGIN, ({ [TUTORIAL_ID]: id }) => {
		gtag('event', 'tutorial_begin', {
			...getCustomParams(user),
			tutorial_id: id
		});
	});

	addEventListener(TUTORIAL_COMPLETE, ({ [TUTORIAL_ID]: id }) => {
		gtag('event', 'tutorial_complete', {
			...getCustomParams(user),
			tutorial_id: id
		});
	});

	addEventListener(ERROR, ({ [SOURCE]: source, [MESSAGE]: message, [STACK]: stack }) => {
		gtag('event', `${source}_error`, {
			...getCustomParams(user),
			message, stack: stack?.substring(0, 98)
		});
	});

	addEventListener(ERROR_PAGE, ({ [STATUS]: status }) => {
		gtag('event', 'error_page', {
			status,
			...getCustomParams(user),
			page_location: location.protocol + '//' + location.host + location.pathname
		});
	});

	addEventListener(WEB_VITALS, ({ [METRIC]: metric, [TARGET]: target, [VALUE]: value, [SOURCE]: source }) => {
		gtag('event', 'web_vital', {
			...getCustomParams(user),
			metric, target, value, source
		});
	});

	addEventListener(TIMING, ({ [CATEGORY]: category, [TYPE]: type, [VALUE]: value, [LABEL]: label }) => {
		gtag('event', 'timing', {
			...getCustomParams(user),
			event_category: category,
			event_label: label,
			name: type,
			bucket: getTimingBucket(value)
		});
	});

	addEventListener(SELECT_GEM_PACK, ({ [ITEM_ID]: id, [ITEM_NAME]: name, [ITEM_CATEGORY]: category,
		[PRICE]: price, [QUANTITY]: quantity }) => {
		gtag('event', 'select_item', {
			...getCustomParams(user),
			items: [
				{
					item_id: id,
					item_name: name,
					item_category: category,
					price, quantity
				}
			]
		});
	});

	addEventListener(SUBSCRIPTION, ({ [SUBSCRIPTION_ID]: id, [SUBSCRIPTION_CURRENCY]: currency, [SUBSCRIPTION_VALUE]: value,
		[SUBSCRIPTION_NAME]: name, [SUBSCRIPTION_LOCATION]: location, [SUBSCRIPTION_COUPON]: coupon, [SUBSCRIPTION_TAX]: tax }) => {
		gtag('event', 'purchase', {
			...getCustomParams(user),
			currency,
			// transaction_id: '',
			value,
			coupon, // include if applicable,
			tax, // include if applicable,
			category: user?.authLevel === 'CLUB' ? 'subs change plan' : 'subs',
			items: [{
				item_id: id,
				item_name: name,
				item_category: 'venus subscription',
				location_id: location
			}]
		});
	});

	addEventListener(CANCELATION, ({ [SUBSCRIPTION_ID]: id, [CANCELATION_STEP]: step }) => {
		gtag('event', 'cancellation', {
			...getCustomParams(user),
			subscription_id: id,
			cancelation_step: step
		});
	});

	addEventListener(GAME_START, ({ [GAME_CODE]: gameCode, [ROUND_COUNT]: roundCount }) => {
		gtag('event', 'page_view', {
			...getCustomParams(user),
			page_location: window.location.origin + '/game_start/' + gameCode,
			page_type: 'virtual',
			round_count: roundCount
		});
	});

	addEventListener(GAME_OVER, ({ [GAME_CODE]: gameCode, [ROUND_COUNT]: roundCount }) => {
		gtag('event', 'page_view', {
			...getCustomParams(user),
			page_location: window.location.origin + '/game_over/' + gameCode,
			page_type: 'virtual',
			round_count: roundCount
		});
	});

	addEventListener(GAME_EXIT, ({ [GAME_CODE]: gameCode }) => {
		gtag('event', 'game_exit', {
			...getCustomParams(user, gameCode)
		});
	});

	addEventListener(LOADING_AD, ({ [GAME_CODE]: gameCode, [PHASE]: phase, [AD_TYPE]: adType }) => {
		switch (phase) {
			case 'init':
				gtag('event', 'page_view', {
					...getCustomParams(user),
					page_location: window.location.origin + '/loading_ad/' + gameCode,
					page_type: 'virtual',
					ad_type: adType
				});
				break;
		}
	});

	addEventListener(INTERMISSION_AD, ({ [GAME_CODE]: gameCode, [PHASE]: phase, [AD_TYPE]: adType, [INT_NUMBER]: intNumber }) => {
		switch (phase) {
			case 'init':
				gtag('event', 'page_view', {
					...getCustomParams(user),
					page_location: window.location.origin + '/intermission/' + gameCode,
					page_type: 'virtual',
					ad_type: adType,
					int_number: intNumber
				});
				break;
		}
	});

	addEventListener(INTERMISSION_COUNTDOWN_START, ({ [INT_NUMBER]: intNumber } ) => {
		gtag('event', 'int_countdown_start', {
			...getCustomParams(user),
			int_number: intNumber
		});
	});

	addEventListener(SDK_STORE_AND_POPUP, ({ [ACTION]: action, [LABEL]: label, [ITEM_NAME]: itemName, [ITEM_CATEGORY]: itemCategory, [ITEM_ID]: itemId }) => {
		gtag('event', 'sdk_store_and_popup', {
			...getCustomParams(user),
			action,
			label,
			item_category: itemCategory,
			item_name: itemName,
			item_id: itemId
		});
	});

	addEventListener(COPPA, ({ [ACTION]: action, [LABEL]: label }) => {
		gtag('event', 'coppa', {
			...getCustomParams(user),
			action,
			label
		});
	});

	addEventListener(TOOLTIP, ({ [TOOLTIP_OPERATION]: tooltipOperation, [TOOLTIP_NAME]: tooltipName, [TOOLTIP_LOCATION]: tooltipLocation = '' }) => {
		gtag('event', 'tooltip', {
			...getCustomParams(user),
			tooltip_operation: tooltipOperation,
			tooltip_name: tooltipName,
			tooltip_location: tooltipLocation
		});
	});

	addEventListener(COMPLETE_CHALLENGE, ({ [CHALLENGE_TYPE]: challengeType, [CHALLENGE_ID]: challengeId }) => {
		gtag('event', 'complete_challenge', {
			...getCustomParams(user),
			challenge_type: challengeType,
			challenge_id: challengeId
		});
	});

	addEventListener(SDK_STORE_AND_POPUP, ({ [ACTION]: action, [LABEL]: label, [ITEM_NAME]: itemName, [ITEM_CATEGORY]: itemCategory, [ITEM_ID]: itemId }) => {
		gtag('event', 'sdk_store_and_popup', {
			...getCustomParams(user),
			action,
			label,
			item_category: itemCategory,
			item_name: itemName,
			item_id: itemId
		});
	});

	addEventListener(GAME_EVENT, params => {
		gtag('event', 'game_event', {
			...getCustomParams(user),
			...params
		});
	});

});

function getTimingBucket(value) {
	if (value < 10) {
		return '< 10';
	}
	else if (value < 20) {
		return '10 - 20';
	}
	else if (value < 30) {
		return '20 - 30';
	}
	else if (value < 60) {
		return '30 - 60';
	}
	return '> 60';
}

function getCustomParams(user, gameCode) {
	gameCode = gameCode || store.getState()[ROUTE].gameCode;
	return {
		environment: POGO_ENVIRONMENT,
		send_to: TAG_ID,
		auth_level: user.authLevel || 'GUEST',
		pogo_id: user.authenticated ? user.id + '' : undefined,
		client_version: CLIENT_VERSION,
		unid: user.unid ? user.unid + '' : undefined,
		game_code: gameCode,
		age: user.age + '',
		under_age: user.underAge ? 'yes' : 'no',
		time_stamp: Date.now(),
		app_mode: '' + (window.matchMedia('(display-mode: standalone)').matches ||
			(window.navigator.standalone) || document.referrer.includes('android-app://'))
	};
}

function sanitize(url) {
	url = url && isExternal(url) ? url.split('?')[0] : url;
	url = url === '/' ? '/home' : url;
	return url;
}

function concatenateStrings(strings) {
	const result = [];
	let currentString = '';

	for (let i = 0; i < strings.length; i++) {
		const stringToAdd = currentString === '' ? strings[i] : ' ' + strings[i];
		if ((currentString + stringToAdd).length > 100) {
			result.push(currentString);
			currentString = strings[i];
		}
		else {
			currentString += stringToAdd;
		}
	}

	result.push(currentString);

	return result;
}
