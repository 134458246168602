import { h } from 'preact';
import classnames from 'classnames';
import styles from './index.css';
import PropTypes from 'prop-types';

export function Grid ({
	class: className,
	style: inlineStyles,
	children,
	id
}) {
	return (
		<div id={id} class={classnames(styles.grid, className)} style={inlineStyles}>
			{children}
		</div>
	);
}

Grid.propTypes = {
	class: PropTypes.string,
	children: PropTypes.any,
	id: PropTypes.string,
	style: PropTypes.string
};

export function Row ({
	class: className,
	children,
	noWrap,
	justify // can be 'start', 'end', or 'between'
}) {
	return (
		<div class={classnames(styles.row, noWrap ? styles.nowrap : styles.wrap, styles[justify], className)}>
			{children}
		</div>
	);
}

Row.propTypes = {
	class: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
	children: PropTypes.any,
	noWrap: PropTypes.bool,
	justify: PropTypes.string
};

export function Col ({
	class: className,
	children,
	sm = 1,
	smp = 2,
	md = 2,
	mdp = 3,
	lg = 4,
	xl = 4,
	xlp = 5
}) {
	return (
		<div class={classnames(styles.col, styles[`col-${sm}-${smp}-${md}-${mdp}-${lg}-${xl}-${xlp}`], className)}>
			{children}
		</div>
	);
}

Col.propTypes = {
	class: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
	children: PropTypes.any,
	sm: PropTypes.number,
	smp: PropTypes.number,
	md: PropTypes.number,
	mdp: PropTypes.number,
	lg: PropTypes.number,
	xl: PropTypes.number,
	xlp: PropTypes.number
};
