import './index.css';
import { logError } from 'services/logger';
import { h, render } from 'preact';
import Router from 'preact-router';
import { Provider } from 'unistore/preact';
import PropTypes from 'prop-types';
import { store } from 'util/store';
import { getRoutes } from 'services/urlService';
import { getQueryStringParams } from 'library/util';
import Page from 'components/framework/page';
import DynamicPage from 'components/framework/dynamicPage';
import Error from 'routes/error';
import { trackWebvitals } from 'services/performance';
import { REGISTRATION } from '@pogo-internal/events/events';
import { dispatchEvent } from '@pogo-internal/events/eventManager';
import { removeQueryParam, setLocalStorage, PAID_UA, AD_TRACKING_PARAMS, AD_QUERY_PARAMS, sleep } from './util';

let App = () => (
	<Provider store={store}>
		<Router>
			{
				getPages()
			}
			<DynamicPage default />
		</Router>
	</Provider>
);

App.propTypes = {
	url: PropTypes.string
};

/**
 * Get page components for all routes configured in config/routes
 */
function getPages() {
	let routes = getRoutes();
	return Object.keys(routes).map(url => getPage(url, routes[url]));
}

function getPage(url, route) {
	try {
		let Component = require(`./routes/${route.component}/index.js`).default;
		return <Page path={url} body={Component} route={route} />;
	}
	catch (e) {
		return <Page path={url} body={Error} route={{ component: 'error', authType: 'none' }} />;
	}
}

trackWebvitals();

const queryParams = getQueryStringParams();

if (queryParams[PAID_UA]) {
	setLocalStorage(PAID_UA, { firstSeen: Date.now(), loadingAdSkipped: false });
	removeQueryParam(PAID_UA);
}

// intersection of query prams and ad tracking params
let paramsToSave = AD_TRACKING_PARAMS.filter(key => key in queryParams)
	.reduce((obj, key) => (obj[key] = queryParams[key], obj), {});

if (Object.keys(paramsToSave).length) {
	setLocalStorage(AD_QUERY_PARAMS, paramsToSave);
}

let event = getQueryStringParams().trackingEvent;
if (event) {
	if (event === 'pogo-account-created') {
		dispatchEvent(REGISTRATION);
	}
	removeQueryParam('trackingEvent');
}

if (process.env.NODE_ENV === 'development') {
	// enable preact devtools
	require('preact/debug');
}

(async function () {
	// wait for shorter of lcp image being rendered or 2.5 s
	try {
		await Promise.race([lcpImageRendered(), sleep(2500)]);
	}
	catch (e) {
		logError(e, 'index - awaitLcp');
	}

	render(h(App), document.body, document.body.firstElementChild);
})();

/** returns a promise that resolves when #lcpCandidate has been rendered on screen */
function lcpImageRendered() {
	return new Promise(resolve => {

		/**
		 * checks if lcp image has rendered, otherwise calls itself on next animation frame
		 * @param repaint number that keeps track of how many repaints have occurred since the image was loaded
		 */
		function checkImage(repaint) {
			let lcpCandidate = document.getElementById('lcpCandidate');
			if (!lcpCandidate)
				return;
			let complete = lcpCandidate.complete;
			if (complete) {
				if (repaint > 5) {
					resolve(); // resolve promise after 6 repaints after image load
					return;
				}
				repaint++;
			}
			requestAnimationFrame(() => checkImage(repaint));
		}
		checkImage(0);
	});
}