import { h, Fragment } from 'preact';
import styles from './index.css';
import classnames from 'classnames';
import { memo } from 'preact/compat';
import { useCallback, useEffect, useState } from 'preact/hooks';
import { isPrerender, onWindowResize, removeOnWindowResize } from 'library/util';
import Image from 'library/components/util/image';
import CloseIcon from 'library/assets/icons/flat/close.svg';
import { connect } from 'unistore/preact';
import { CONSENT_MANAGER_LOADED } from 'util/store';
import { checkAdEnabled } from 'util/index';

window.ramp = window.ramp || {};
window.ramp.que = window.ramp.que || [];
window.ramp.passiveMode = true;

const ATF_PLACEMENTS = {
	'728x90': 'leaderboard_atf',
	'970x90': 'leaderboard_btf',
	'160x600': 'sky_atf',
	'300x600': 'sky_btf',
	'320x50': 'leaderboard_atf',
	'468x60': 'leaderboard_atf',
	'300x250': 'med_rect_atf'
};

const PlaywireAd = memo(({ type, id }) => {
	useEffect(() => {
		let unit = { type };
		if (id) {
			unit.selectorId = id;
		}
		window.ramp.que.push(async () => {
			await window.ramp.addUnits([unit]); // TODO try catch
			await window.ramp.displayUnits();
		});
		return () => {
			window.ramp.que.push(() => {
				cleanUp(id);
			});
		};
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	return (
		id && <div id={id} />
	);
});

function cleanUp(parentId) {
	// possible that component was removed before first ad was created
	if (!window.ramp.settings || !window.ramp.settings.slots)
		return;

	let slotToRemove = null;
	Object.entries(window.ramp.settings.slots).forEach(([slotName, slot]) => {
		if (
			slot.element
			&& slot.element.parentElement
			&& slot.element.parentElement.id === parentId
		) {
			slotToRemove = slotName;
		}
	});

	if (slotToRemove) {
		window.ramp.destroyUnits(slotToRemove);
	}
}

export const Ad = connect(`${CONSENT_MANAGER_LOADED}`)(memo(({
	user,
	location,
	fixed,
	slots,
	class: additionalClass,
	consentManagerLoaded,
	init = true
}) => {

	const getCurrentSlot = useCallback(() => {
		let _slot;
		slots.forEach(slot => {
			if (window.innerWidth >= slot.viewport[0]) {
				_slot = slot;
			}
		});
		return _slot;
	}, [slots]);

	const [ slotDimension, setSlotDimension ] = useState(getCurrentSlot().size);
	const [ closed, setClosed ] = useState(false);
	const [ width, height ] = slotDimension;

	const onResize = useCallback(() => {
		const newSlotDimension = getCurrentSlot().size;
		const [newWidth, newHeight] = newSlotDimension;
		if (newWidth !== width || newHeight !== height) {
			setSlotDimension(newSlotDimension);
		}
	}, [width, height, getCurrentSlot]);

	const closeAd = useCallback(() => {
		setClosed(true);
	}, []);

	useEffect(() => {
		onWindowResize(onResize);
		return () => removeOnWindowResize(onResize);
	}, [onResize]);

	isPrerender && (fixed = ''); // to not prerender fixed ad
	let type = getType(`${width}x${height}`);
	let id = `${type}-${location}`;

	return !!width && !closed && (
		<Fragment>
			<div
				class={classnames(styles.adParent, fixed && styles.fixed, additionalClass)}
			>
				{
					consentManagerLoaded && checkAdEnabled(user) && init &&
					<PlaywireAd type={type} id={id} key={id} />
				}
			</div>
			{
				fixed &&
				<div class={styles.closeButton} onClick={closeAd}><Image alt={'Ad Close Icon'} src={CloseIcon} /></div>
			}
		</Fragment>
	);
}));

function getType(dimension) {
	return ATF_PLACEMENTS[dimension];
}