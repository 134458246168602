import { h } from 'preact';
import classnames from 'classnames';
import styles from './index.css';
import PropTypes from 'prop-types';

export default function Overlay({
	type = 'default',
	class: additionalClass,
	onClick = () => {}
}) {
	return (
		<div onClick={onClick} class={classnames(styles.overlay, styles[type], additionalClass)} />
	);
}

Overlay.propTypes = {
	type: PropTypes.string,
	class: PropTypes.string,
	onClick: PropTypes.func
};
